
import Vue from "vue";
import Dialog from "../popups/Dialog.vue";
import {mapMutations, mapState} from "vuex";
import { applicationsService } from "@/services";
import ErrorHelper from "@/helpers/errorHelper";
import { EventBus } from "@/helpers/EventBus";
import {ApplicationEvents} from "@/enums";
import { BadRequestError } from "@/models/Errors";

export default Vue.extend({
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Dialog
  },
  computed: {
    ...mapState(["activeCompany"])
  },
  props: {
   value: {
     type: Boolean
   },
   appId: {
    type: Number
   },
   appTitle: {
    type: String
   }
  },
  data(): {
    deleting: boolean;
    deletingDelay: number;
  } {
    return {
      deleting: false,
      deletingDelay: 1000,
    };
  },
  methods: {
    ...mapMutations(["changeAppSelector"]),
    async deleteApp(): Promise<void> {
      const delay = 2000;
      this.deleting = true;
      try {
        await applicationsService.deleteApplication({ AppId: this.appId, CompanyId: this.activeCompany.Id  });
        await new Promise(resolve => setTimeout(resolve, this.deletingDelay));
        ErrorHelper.addSnackbarMessage(`Delete has been successfully queued for application ${this.appTitle}.`, 'success', '', false, 0, delay);
        this.changeAppSelector();
        EventBus.$emit(ApplicationEvents.applicationDeleted);
      }
      catch (e) {
        if (e instanceof BadRequestError) {
          ErrorHelper.addSnackbarMessage((e.message || '').replace('{0}', this.appTitle), 'error', '');
        } else {
         ErrorHelper.addSnackbarMessage(`Delete was unsuccessful. Please try again or contact your System Administrator if the issue persists.`, 'error', '', true);
        }
      }
      finally {
         this.$emit('input', false);
        this.deleting = false;
      }
    }
  }
});
