import Router from "vue-router";
import LoginCallback from "common-vue-components/components/LoginCallback.vue";
import RouterHelper from "@/helpers/routerHelper";

const router = new Router({
  mode: "history",
  routes: [
    {
      path: RouterHelper.buildRoute(),
      redirect: RouterHelper.buildRoute("Main")
    },
    {
      path: RouterHelper.buildRoute(":guid/Main"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: RouterHelper.buildRoute("login/callback"),
      component: LoginCallback
    }
  ]
});

export default router;
