export enum ContextMenuItemKey {
  divider,
  copyLink,
  editSubmission,
  deleteSubmission,
  publishSubmission,
  addFiles,
  deleteFile,
  downloadFile,
  editFileTitle,
  editFileName = 9
}
