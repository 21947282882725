
import Vue from "vue";
import {EventBus} from "../../helpers/EventBus";

export default Vue.extend({
    data(): {
        showCopyFailed: boolean;
    } {
        return {
            showCopyFailed: false
        };
    },
    methods: {
        focusOnLink(): void {
            (this.$refs.focusThis as HTMLFormElement).focus();
            (this.$refs.focusThis as HTMLFormElement).select();
        }
    },
    watch: {
        failedLinkToShare(): void {
            if (this.failedLinkToShare) {
                this.showCopyFailed = true;
            }
        },
        showCopyFailed(): void {
            if (this.showCopyFailed) {
                this.$nextTick(() => {
                    this.focusOnLink();
                });
            } else {
                this.$emit("changeFailedLinkToShare", "");
            }
        }
    },
    props: {
      failedLinkToShare: {
        type: String
      }
    },
  mounted() {
    EventBus.$on("changeFileToDownload", (filePath: string) => {
      this.$emit("changeFileToDownload", filePath);
    });
    EventBus.$on("changeFailedLinkToShare", (result: any) => {
      this.$emit("changeFailedLinkToShare", result);
    });
  }
});
