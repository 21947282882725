import { BadRequestError } from '@/models/Errors';
import { StartFileUploadResponse } from "@/models/WebPublish/Responses";
import { CompleteFilesUploadRequest, ChangeFileTitleRequest, ChangeFileNameRequest, StartFilesUploadRequest } from "@/models/WebPublish/Requests";

export class FilesService {
  public async startFilesUpload(companyId: number, request: StartFilesUploadRequest): Promise<StartFileUploadResponse[]> {
    const response = await fetch(`${window.sessionUrl}Files/StartFilesUpload`, {
      method: "POST",
      body: JSON.stringify({ companyId, request }),
      headers: {
        "Content-Type": "application/json",
      }
    });

    if (response.ok) {
      return JSON.parse(await response.text());
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400: throw new BadRequestError(errorMessage);
        default: throw Error(errorMessage);
      }
    }
  };

  public async completeFilesUpload(companyId: number, request: CompleteFilesUploadRequest): Promise<void> {
    const response = await fetch(`${window.sessionUrl}Files/CompleteFilesUpload`, {
      method: "POST",
      body: JSON.stringify({ companyId, request }),
      headers: {
        "Content-Type": "application/json",
      }
    });

    if (response.ok) {
      return;
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400: throw new BadRequestError(errorMessage);
        default: throw Error(errorMessage);
      }
    }
  };

  public async changeFileTitle(companyId: number, request: ChangeFileTitleRequest): Promise<void> {
    const response = await fetch(`${window.sessionUrl}Files/ChangeFileTitle`, {
      method: "POST",
      body: JSON.stringify({companyId, request}),
      headers: {
        "Content-Type": "application/json",
      }
    });

    if (response.ok) {
      return;
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400: throw new BadRequestError(errorMessage);
        default: throw Error(errorMessage);
      }
    }
  };
  
  public async changeFileName(companyId: number, request: ChangeFileNameRequest): Promise<void> {
    const response = await fetch(`${window.sessionUrl}Files/ChangeFileName`, {
      method: "POST",
      body: JSON.stringify({companyId, request}),
      headers: {
        "Content-Type": "application/json",
      }
    });

    if (response.ok) {
      return;
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400: throw new BadRequestError(errorMessage);
        default: throw Error(errorMessage);
      }
    }
  };
  
  public async deleteFile(companyId: number, appId: number, subId: number, fileUseHistoryId: number): Promise<void> {
    const response = await fetch(`${window.sessionUrl}Files/DeleteFile`, {
      method: "POST",
      body: JSON.stringify({companyId, appId, subId, fileUseHistoryId}),
      headers: {
        "Content-Type": "application/json",
      }
    });

    if (response.ok) {
      return;
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400: throw new BadRequestError(errorMessage);
        default: throw Error(errorMessage);
      }
    }
  };
}

export const filesService = new FilesService();
