
import $ from "jquery";
import "jquery-resizable-dom";
import Vue from "vue";
import VueVisible from "vue-visible";
import { EventBus } from "@/helpers/EventBus";
import { TreeHelper } from "@/helpers/treeHelper";
import TreeComponent from "@/models/treeComponent";
import { FileComponent } from "@/models/fileComponent";
import { ViewHistory } from "@/pdf/history-tracker";
import ComponentService from "@/services/componentService";
import AppSelector from "@/components/appselector/AppSelector.vue";
import Inventory from "@/components/details/Inventory.vue";
import ViewerOverlay from "@/components/details/ViewerOverlay.vue"
import XmlViewer from "@/components/details/XmlViewer.vue";
import AppLoader from "@/components/loaders/AppLoader.vue";
import PublishLoader from "@/components/loaders/PublishLoader.vue";
import TreeLocationNav from "@/components/loaders/TreeLocationNav.vue";
import AppDeleteDialog from '@/components/appselector/AppDeleteDialog.vue';
import CopyFailedAlert from "@/components/popups/CopyFailedAlert.vue";
import ViewerToolbar from "@/components/navs/ViewerToolbar.vue";
import Trees from "@/components/tree/Trees.vue";
import { Panels, FileTypes, TreeType } from "@/helpers/Enums";
import UserSettingsComponent from "@/models/userSettingsComponent";
import UserRightsComponent from "@/models/userRightsComponent";
import {ModalWindow} from "@/enums";
import {mapMutations, mapState} from "vuex";
import {RootMutations} from "@/store";
require("../Content/Main/Main.css")
require("../Content/PreviewPane/PreviewPane.css")
require("../Content/Common/Common.css")

Vue.use(VueVisible);

export default Vue.extend({
  components: {
    AppLoader,
    AppSelector,
    Inventory,
    TreeLocationNav,
    ViewerToolbar,
    Trees,
    XmlViewer,
    ViewerOverlay,
    PublishLoader,
    CopyFailedAlert,
    AppDeleteDialog
  },
  data(): {
    bind: boolean;
    inventoryNode: TreeComponent | null;
    loaded: boolean;
    showAppLoader: boolean;
    isPublishing: boolean;
    showPreviewPane: boolean;
    showPdfViewer: boolean;
    treeExpanded: boolean;
    showPdfOverlay: boolean;
    isOpenRecent: boolean;
    tooltips: any[];
  } {
    return {
      bind: false,
      inventoryNode: new TreeComponent(),
      loaded: false,
      showAppLoader: false,
      isPublishing: false,
      showPreviewPane: true,
      showPdfViewer: true,
      treeExpanded: true,
      showPdfOverlay: false,
      isOpenRecent: false,
      tooltips: []
    };
  },
  methods: {
    ...mapMutations({
      close: RootMutations.closeModalWindow,
    }),
    hideDeletingApplication(value: boolean): void {
      !value && this.close();
    },
    refreshInventory(): void {
      (this.$refs.inventoryGrid as any).thisGrid().dataSource.page(1);
    },
    isAppOpen(): void {
      const url = "./Main/IsAppOpen";
      new ComponentService().isAppOpen(this.isAppOpenSuccess, url);
    },
    getLinkShareRequest(): any {
      return new URL(window.location.href).searchParams.get("id");
    },
    isAppOpenSuccess(result: any): void {
      result = result as boolean;
      if (!result) {
        this.setView(Panels.AppSelector);
        this.$nextTick(() => {
          this.loaded = true;
          EventBus.$emit("mainComponent_openApp");
        });
      } else {
        this.showAppLoader = true;
        this.setAppTitle();
        this.setView(Panels.Viewer);
        this.$nextTick(() => {
          this.loaded = true;
          EventBus.$emit("mainComponent_refreshTree");
        });
      }
    },
    refreshDisplay(): void {
      this.$emit('changeSelectedNode', new TreeComponent());
      this.showAppLoader = true;
      this.setAppTitle();
      this.$emit('changeSearchText',"");
      if (this.showAppSelector) {
        this.$emit('changeAppSelector');
        ViewHistory.clear();
      }

      EventBus.$emit("clearSnackbar");
      EventBus.$emit("mainComponent_refreshTree", null);
      this.$emit('changeTreeSearchResults',[]);
      EventBus.$emit("treeToolbar_resetSearch");

      this.refreshEmptySelectedNodeDisplay();
      this.inventoryNode = null;
    },
    setAppTitle(): void {
      const url = "./Main/GetAppInformation";
      new ComponentService().getAppTitle(this.setAppTitleSuccess, url);
    },
    setAppTitleSuccess(result: string): void {
      this.$emit('changeAppTitle', result);
    },
    setView(view: Panels): void {
      if (view === null) {
        return;
      }

      switch (view) {
        case Panels.Viewer:
        case Panels.Details:
          this.$emit('changeNonAppActiveView', view);
          break;
        case Panels.AppSelector:
          this.$emit('setShowAppSelector');
          break;
        case Panels.XmlViewer:
          this.$emit('changeNonAppActiveView', view);
          break;
      }
    },
    getFileFromSelection(fuhId: number, fileType: number | null, isDeletePlaceholder: boolean = false,
        Exists: boolean = true, hasXfa: boolean = false, isPlaceholder: boolean = false): void {
        if (fuhId) {
            this.$emit('changeLoadedDoc', new FileComponent(fuhId, fileType as FileTypes, isDeletePlaceholder, Exists, null, hasXfa, isPlaceholder));
            if (fileType === FileTypes.PDF) {
                this.$emit('changeNonAppActiveView', Panels.Viewer);
            }
        }
    },
    onFullScreenChange(): void {
      const fullScreenElement =
          document.fullscreenElement ||
          document.msFullscreenElement ||
          document.mozFullScreenElement ||
          document.webkitFullscreenElement;

      if (fullScreenElement == null) {
        this.$emit('changeIsFullScreen', false);
      }
    },
    initializeFullscreenEvents(): void {
      if (document.onfullscreenchange === null)
        document.onfullscreenchange = this.onFullScreenChange;
      else if (document.onmsfullscreenchange === null)
        document.onmsfullscreenchange = this.onFullScreenChange;
      else if (document.onmozfullscreenchange === null)
        document.onmozfullscreenchange = this.onFullScreenChange;
      else if (document.onwebkitfullscreenchange === null)
        document.onwebkitfullscreenchange = this.onFullScreenChange;
    },
    tooltipOnHover(sender: any, message: string): void {
      let tooltip = this.tooltips.filter(x => x.message == message);
      let position = sender.getBoundingClientRect();
      let y = 15 + position.y;
      if(tooltip.length == 0) {
        this.tooltips.push({
          x: position.x,
          y,
          message,
          show: true
        });
      }
      else {
        tooltip[0].show = true;
        tooltip[0].x = position.x;
        tooltip[0].y = y;
      }
    },
    tooltipOffHover(message: string): void {
      (this.tooltips.filter(x => x.message == message)[0] || {}).show = false;
    },
    warmServices() {
      setTimeout(() => {
        let services = new ComponentService();
        services.requestWrapper({}, () => false, () => false, 'LinkToShare/CopyLink?', true);
        services.requestWrapper({}, () => false, () => false, 'Origin/GetSections?', true);
      }, 1000);
    },
    refreshEmptySelectedNodeDisplay() {
      this.$emit('changeLoadedDoc', new FileComponent());
      this.$emit('changeIsDocumentLoaded',false);
      this.$emit('changeNonAppActiveView', Panels.Viewer);
      if (window.previewPane) {
        window.previewPane.clearDocumentAndHistory();
      }
    },
  },
  computed: {
    ...mapState(["selectedApplicationId"]),
    showPublishLoader(): boolean {
      return this.isPublishing;
    },
    showDeletingApplication(): boolean {
      return this.$store.state.modalWindow === ModalWindow.deleteApplication;
    }
  },
  mounted(): void {
    EventBus.$on("tooltipOnHover", this.tooltipOnHover);
    EventBus.$on("tooltipOffHover", this.tooltipOffHover);

    // rework this to be more vue-like
    this.$nextTick(() => {
      ($(".trees") as any).resizable({
        handleSelector: ".splitter",
        resizeHeight: false,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onDragEnd(e: any, el: any, opt: any): void {
          EventBus.$emit("checkPdfToolbar");
        }
      });

      if (!this.getLinkShareRequest()) {
        this.isAppOpen();
      }
    });

    this.$emit('getUserSettings');
    this.$emit('getUserRights');
    this.initializeFullscreenEvents();

    EventBus.$on("changeFileToDownload", (filePath: string) => {
      this.$emit("changeFileToDownload", filePath);
    });

    EventBus.$on("publishStateChanged", (isPublishing: boolean) => {
        this.isPublishing = isPublishing;
    });
  },
  watch: {
    linkShareToOpen() {
      this.loaded = true;
    },
    loadingInformation() {
      const trueCount = this.loadingInformation.filter(
          (val: boolean) => val === true
      ).length;
      if (trueCount === 5) {
        this.showAppLoader = false;
      }
    },
    selectedNode() {
      if (this.selectedNode.Id === "") {
        this.refreshEmptySelectedNodeDisplay();
        return;
      }

      const treeNode = TreeHelper.makeTreeComponent(this.selectedNode);
      const fuhId = treeNode.FileUseHistoryId;

      if (fuhId) {
        this.getFileFromSelection(fuhId,
            treeNode.FileType,
            treeNode.FileUseStateId === 1 && treeNode.ActionType === 2,
            treeNode.DoesExist,
            treeNode.HasXfa,
            treeNode.IsPlaceholder,
        );
      } else {
        this.inventoryNode = treeNode;
        this.$emit('changeNonAppActiveView', Panels.Details);
      }
    },
    showAppSelector() {
      if (!this.showAppSelector) {
        this.setView(this.nonAppActiveView);
      }
    },
    nonAppActiveView() {
      this.showPreviewPane =
          this.nonAppActiveView === (Panels.Viewer as number) ||
          this.nonAppActiveView === (Panels.XmlViewer as number) ||
          this.nonAppActiveView === (Panels.TxtViewer as number) ||
          this.nonAppActiveView === (Panels.Error as number) ||
          this.nonAppActiveView === (Panels.ViewerOverlay as number) ||
          this.nonAppActiveView === (Panels.ImageViewer as number);
      this.showPdfViewer = this.nonAppActiveView === (Panels.Viewer as number);
    },
    changeOpenMenuCount(){
      this.showPdfOverlay = this.openMenuCount > 0
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fileToDownload(path: string): void {}
  },
  props: {
    activeTree: { type: Number, default: TreeType.Origin},
    appTitle: String,
    canGoBack: Boolean,
    canGoForward: Boolean,
    drawerState: Boolean,
    failedLinkToShare: { type: String, default: ""},
    isFullScreen: Boolean,
    isDocumentLoaded: Boolean,
    isSearchOpen: Boolean,
    isUnsupportedFile: Boolean,
    // eslint-disable-next-line vue/require-valid-default-prop
    loadingInformation: { type: Array as () => boolean[], default: [false, false, false, false, false] },
    loadedDoc: { type: Object as () => FileComponent, default: new FileComponent() },
    loadedPdfPath: { type: Object, default: null },
    nonAppActiveView: { type: Number, default: Panels.Viewer },
    searchText: { type: String, default: "" },
    selectedNode: { type: Object as () => TreeComponent, default: new TreeComponent() },
    showAppSelector: Boolean,
    showContextMenu: Boolean,
    showDetails: Boolean,
    showHide: { type: Boolean, default: true },
    showTrees: { type: Boolean, default: true },
    treeLegendState: Boolean,
    treeSearchResults: { type: Array, default: () => [] },
    userSettings: { type: Object as () => UserSettingsComponent, default: new UserSettingsComponent() },
    userRights: { type: Object as () => UserRightsComponent, default: new UserRightsComponent() },
    openMenuCount: { type: Number, default: 0 },
    fileToDownload: { type: String, default: "" },
    recentsLoaded: Boolean,
    showOverlayMessage: { type: String, default: "" },
    inventoryNodeToOpen: { type: Object, default: null },
    userGuideUrl: { type: String, default: "" },
    loadedPdfDoc: { type: Boolean },
    isWebPublish: Boolean,
    isWebPublishReleased: { type: Boolean, default: false},
    linkShareToOpen: { type: String, default: null },
  }
});
