
import $ from "jquery";
import { Grid, GridInstaller } from "@progress/kendo-grid-vue-wrapper";
import Vue from "vue";
import ComponentService from "../../services/componentService";
import { EventBus } from "../../helpers/EventBus";
import RecentAppSelectorDrawer from "./RecentAppSelectorDrawer.vue"
import UserRightsComponent from "../../models/userRightsComponent";
import { mapGetters } from "vuex";
import { ApplicationEvents } from "@/enums";
import {RootMutations} from "@/store";
require("../../Content/AppSelector/AppSelector.css")
require("../../Content/Common/Common.css")

// required for kendo
Vue.use(GridInstaller);

export default Vue.extend({
  mounted(): void {
    EventBus.$on(ApplicationEvents.applicationCreated, (appId: number) => this.appOnCreated(appId));
    EventBus.$on(ApplicationEvents.applicationDeleted, () => this.thisGrid().dataSource.read());

    this.$nextTick(() => this.thisGrid().dataSource.bind("error", this.onAppGridError));
    this.isMounted = true;
  },
  beforeDestroy() {
    EventBus.$off(ApplicationEvents.applicationCreated);
    EventBus.$off(ApplicationEvents.applicationDeleted);
  },
  components: {
    RecentAppSelectorDrawer
  },
  data(): {
    appSelectButtonDisabled: boolean;
    hasApps: boolean;
    noSearchResults: boolean;
    gridLoaded: boolean;
    searchText: string;
    recentApps: any;
    openingApp: boolean;
    isOpenRecent: boolean;
    isMounted: boolean;
    contextMenuVisible: boolean;
    contextMenuApp: any;
    contextMenuXOffset: number;
    contextMenuYOffset: number;
  } {
    return {
      appSelectButtonDisabled: true,
      hasApps: false,
      noSearchResults: false,
      gridLoaded: false,
      searchText: "",
      recentApps: null,
      openingApp: false,
      isOpenRecent: false,
      isMounted: false,
      contextMenuVisible: false,
      contextMenuApp: undefined,
      contextMenuYOffset: 0,
      contextMenuXOffset: 0,
    };
  },
  methods: {
    getRowContextMenuIconTemplate() {
      return `
        <a role="button" class="k-button-icontext k-grid-contextButton" aria-haspopup="menu">
          <span class="k-icon k-i-more-vertical"/>
        </a>
      `;
    },
    getAppNumberTemplate(app: any) {
      return `
        <div class="d-flex">
          <span>${app.AppNumber || ''}</span>
        </div>
      `;
    },
    createNewApp() {
      this.$emit("createNewApp");
    },
    async appOnCreated(appId: number): Promise<void> {
      const grid = this.thisGrid();

      await grid.dataSource.read();
      const appItems = grid.dataSource.data();

      const appItem = appItems.find((appItem : any) => appItem.AppId === appId);

      this.openAppItem(appItem);
    },
    appOnChange(): void {
      this.appSelectButtonDisabled = false;
    },
    appOnDataBound(): void {
      const grid = this.thisGrid();

      if (grid.dataSource.data().length > 0) {
        this.hasApps = true;
      }

      grid.element.on("click", `[aria-haspopup='menu']`, (e) => {
        e.stopPropagation();

        const rowElement = $(e.currentTarget).closest('tr');

        this.contextMenuXOffset = e.pageX;
        this.contextMenuYOffset = e.pageY + 10;
        this.contextMenuVisible = true;
        this.contextMenuApp = this.thisGrid().dataItem(rowElement);
      });

      grid.element.on("click", "tbody tr[data-uid]", () => {
        this.openSelectedApp();
      });

      grid.element.on("click", "tbody tr[data-uid]", () => {
        this.openSelectedApp();
      });

      grid.table.on("keydown", (event: any) => {
        if (event.keyCode === 38 || event.keyCode === 40) /* up and down arrow */ {
          grid.select($("#appSelectorGrid_active_cell").closest("tr"));
        }
      });

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      grid.table.on("focus", (event: any) => {
        grid.select($("#appSelectorGrid_active_cell").closest("tr"));
      });

      grid.element.on("keyup", (event: any) => {
        if (event.originalEvent.keyCode === 13) /* enter */ {
          this.openSelectedApp();
        }
      });

      this.gridLoaded = true;
      this.$emit("gridLoaded", true);
    },
    applyFilter(searchText: string, grid: kendo.ui.Grid): void {
      const filter = { logic: "or", filters: [] };
      this.filterGrid(grid, filter, searchText);
    },
    filterGrid(grid: kendo.ui.Grid, filter: any, searchText: string): void {
      const columns = grid.columns;

      columns.forEach(column => {
        if (column.field) {
          filter.filters.push({
            field: column.field,
            operator: "contains",
            value: searchText
          });
        }
      });
      grid.dataSource.filter(filter);
    },
    thisGrid(): kendo.ui.Grid {
      return (this.$refs.appGrid as Grid).kendoWidget() as kendo.ui.Grid;
    },
    onAppGridError(event: any): void {
      if (event.xhr.status === 403 || event.xhr.responseText.toLowerCase().includes("session expired")) {
        this.$emit("changeSessionExpired");
      }
    },
    openRecentApp(appComponent: any): void {
      EventBus.$emit("appSelector_clearSelectedNode")
      new ComponentService().openApp(appComponent, () => this.openAppSuccess(appComponent), `./AppSelector/OpenApp?companyId=${this.companyId}`)
    },
    openSelectedApp(): void {
      if (this.openingApp) {
        return;
      }

      const grid = this.thisGrid();
      const appItem = grid.dataItem(grid.select());
      if (!appItem) {
        return;
      }

      this.openAppItem(appItem);
    },
    openAppItem(appItem: any): void {
      this.openingApp = true;

      EventBus.$emit("appSelector_clearSelectedNode")

      const app = JSON.parse(JSON.stringify(appItem));
      new ComponentService().openApp(app, () => this.openAppSuccess(app), `./AppSelector/OpenApp?companyId=${this.companyId}`);
    },
    openAppSuccess(data: any): void {
      this.$emit("resetLoadingInformation");
      this.$nextTick(() => {
        this.removeSearchText();
        this.$emit("appSelector_refreshDisplay");
        window.history.pushState("main", "Main", window.sessionUrl + "Main");
        this.openingApp = false;
        this.$store.commit(RootMutations.setSelectedApp, data.AppId);
      });
    },
    removeSearchText(): void {
      this.noSearchResults = false;
      this.searchText = "";
      (this.$refs.searchInput as HTMLInputElement).blur();
      const grid = this.thisGrid();
      this.applyFilter("", grid);
      this.appSelectButtonDisabled = true;
    },
    changeAppSelector(): void {
      this.$emit("changeAppSelector");
    }
  },
  watch: {
    showAppSelector() {
      if (this.showAppSelector && this.isMounted) {
        this.hasApps = false;
        this.gridLoaded = false;
        this.$emit("gridLoaded", false);
        this.$emit("changeRecentsLoaded", false);
        this.appSelectButtonDisabled = true;
        this.searchText = "";
        const grid = this.thisGrid();
        grid.dataSource.read();
      }
    },
    searchText() {
      if (this.searchText === null) {
        return;
      }

      this.appSelectButtonDisabled = true;

      const grid = this.thisGrid();
      this.applyFilter(this.searchText, grid);

      // any cast used to get access to private variable outside of typed scope
      if ((grid as any)._data.length === 0) {
        this.noSearchResults = true;
      } else {
        this.noSearchResults = false;
      }
    }
  },
  computed: {
    ...mapGetters(["companyId"]),
    hideAppSelectorAndHasTitle(): boolean {
      return this.appTitle !== '' && !this.showAppSelector;
    },
    remoteDataSource(): kendo.data.DataSource {
      return new kendo.data.DataSource({
        pageSize: 25,
        serverPaging: false,
        transport: {
          read: {
            cache: false,
            dataType: "json",
            url: `./AppSelector/GetApps?companyId=${this.companyId}&isPublish=${this.isWebPublish}`
          }
        }
      })
    }
  },
  props: {
    showAppSelector: Boolean,
    appTitle: String,
    recentsLoaded: Boolean,
    isWebPublish: Boolean,
    userRights: {
      type: Object as () => UserRightsComponent,
      default: new UserRightsComponent()
    },
  }
});
