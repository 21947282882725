import Vue from "vue";
import Vuex from "vuex";
import { Panels, FileTypes, TreeType } from "@/helpers/Enums";
import { FileComponent } from "@/models/fileComponent";
import UserSettingsComponent from "@/models/userSettingsComponent";
import UserRightsComponent from "@/models/userRightsComponent";
import { Company }  from "common-vue-components/models/company";
import { ConfigurationModel } from "common-vue-components/models/configurationModel";
import GlobalHelper from "@/helpers/globalHelper";
import ErrorHelper from "@/helpers/errorHelper";
import $ from "jquery";
import { RootState } from "./types";
import TreeComponent from "@/models/treeComponent";
import {ModalWindow} from "@/enums";

Vue.use(Vuex);

export enum RootMutations {
  openModalWindow = "openModalWindow",
  closeModalWindow = "closeModalWindow",
  setSelectedApp = "setSelectedApp",
  setSelectedSub = "setSelectedSub",
}

const publishMode = /true/i.test(window.publishMode);

const state: RootState = {
  isPublishMode: publishMode,
  webApplicationName: publishMode ? "Publish" : "WebReview",
  activeTree: TreeType.Origin,
  appTitle: "",
  canGoBack: false,
  canGoForward: false,
  contactDialogState: false,
  drawerState: false,
  failedLinkToShare: "",
  infoDialogState: false,
  isFullScreen: false,
  isDocumentLoaded: false,
  isSearchOpen: false,
  isUnsupportedFile: false,
  loadingInformation: [false, false, false, false, false],
  loadedDoc: new FileComponent(),
  loadedPdfPath: null,
  nonAppActiveView: Panels.Viewer,
  searchText: "",
  selectedNode: new TreeComponent(),
  sessionExpired: false,
  showAppSelector: false,
  showContextMenu: false,
  showDetails: false,
  showHide: !publishMode,
  showTrees: true,
  treeLegendState: false,
  treeSearchResults: [],
  userSettings: new UserSettingsComponent(),
  userRights: new UserRightsComponent(),
  openMenuCount: 0,
  fileToDownload: "",
  recentsLoaded: false,
  showOverlayMessage: "",
  inventoryNodeToOpen: null,
  userGuideUrl: "",
  activeCompany: null,
  companies: [],
  configurationInfo: ConfigurationModel.FromObject({}),
  areCompaniesLoading: true,
  modalWindow: ModalWindow.none,
  selectedApplicationId: null,
  selectedSubId: null
}

export default new Vuex.Store({
  state: state,
  actions: {
    updateBookmarkPanelOpenSetting(
      { commit, state },
      isBookmarkPanelOpen: boolean
    ) {
      $.ajax({
        cache: false,
        url: "./UserSettings/SetBookmarkPanelOpenSetting",
        data: { isBookmarkPanelOpen: isBookmarkPanelOpen },
      });
      let settings = state.userSettings;
      if (settings) settings.BookmarkPanelOpen = isBookmarkPanelOpen;
      else settings = new UserSettingsComponent(isBookmarkPanelOpen);
      commit("updateUserSettings", settings);
    },
    getUserSettings({ commit }) {
      $.ajax({
        cache: false,
        url: "./UserSettings/GetUserSettings",
        success: function (result) {
          const settings = new UserSettingsComponent(result.BookmarkPanelOpen);
          commit("updateUserSettings", settings);
        },
      });
    },
    getUserRights({ commit }) {
      $.ajax({
        cache: false,
        url: "./UserRights/Get",
        success: function (result) {
          const rights = new UserRightsComponent(
            result.HasPublish,
            result.HasPublishAdmin
          );
          commit("updateUserRights", rights);
        },
      });
    },
    async getCompaniesForUser({ commit, dispatch }) {
      const companies: [Company] = (
        await GlobalHelper.http(
          `${window.sessionUrl}Companies/GetCompaniesForUser`,
          ErrorHelper.CompaniesGetError
        )
      ).map(
        (x: any) =>
          new Company(
            x.Id,
            x.Name,
            x.Expiration ? new Date(x.Expiration) : null
          )
      );

      commit("updateUserCompanies", companies);

      if (companies && companies.length === 1 && !companies[0].isExpired) {
        dispatch("changeActiveCompany", companies[0]);
      }
    },
    async getConfigData({ state, commit }) {
      if (!state.activeCompany) {
        return;
      }
      const configInfo: ConfigurationModel = await GlobalHelper.http(
        `${window.sessionUrl}Configuration/GetConfiguration?companyId=${
          (state.activeCompany as unknown as Company).Id
        }`,
        ErrorHelper.ConfigurationGetError
      );
      commit("changeConfigurationInfo", configInfo);
    },
    async changeActiveCompany(
      { state, commit, dispatch },
      company: Company,
      showAppSelector: Boolean = true
    ) {
      if (company.Id === state.activeCompany?.Id) return;

      if (company.isExpired) {
        ErrorHelper.addSnackbarMessage(
          "Access has Expired, please contact support for more details.",
          "error",
          "",
          true
        );
        return;
      }

      showAppSelector && commit("setShowAppSelector");
      commit("setActiveCompany", company);
      dispatch("getConfigData");
    }
  },
  mutations: {
    [RootMutations.openModalWindow]: (state, modelWindow: ModalWindow) => {
      state.modalWindow = modelWindow;
    },
    [RootMutations.closeModalWindow]: (state) => {
      state.modalWindow = ModalWindow.none
    },
    [RootMutations.setSelectedApp]: (state, appId: number | null) => {
      state.selectedApplicationId = appId;
    },
    [RootMutations.setSelectedSub]: (state, subId: number | null) => {
      state.selectedSubId = subId;
    },
    changeAppSelector: (state): void => {
      state.showAppSelector = !state.showAppSelector;
    },
    changeAppTitle: (state, title): void => {
      state.appTitle = title;
    },
    setShowAppSelector: (state): void => {
      state.showAppSelector = true;
    },
    setHideAppSelector: (state): void => {
      state.showAppSelector = false;
    },
    changeCanGoBack: (state, canGoBack): void => {
      state.canGoBack = canGoBack;
    },
    changeCanGoForward: (state, canGoForward): void => {
      state.canGoForward = canGoForward;
    },
    changeContactDialogState: (state): void => {
      state.contactDialogState = !state.contactDialogState;
    },
    changeDrawerState: (state): void => {
      state.drawerState = !state.drawerState;
    },
    changeFailedLinkToShare: (state, url): void => {
      state.failedLinkToShare = url;
    },
    changeInfoDialogState: (state): void => {
      state.infoDialogState = !state.infoDialogState;
    },
    changeIsUnsupportedFile: (state, isUnsupported): void => {
      state.isUnsupportedFile = isUnsupported;
    },
    changeLoadingInformation: (state, index): void => {
      Vue.set(state.loadingInformation, index, true);
    },
    changeNonAppActiveView: (state, view): void => {
      state.nonAppActiveView = view;
    },
    changeSelectedNode: (state, node): void => {
      state.selectedNode = node;
    },
    changeSessionExpired: (state): void => {
      state.sessionExpired = !state.sessionExpired;
    },
    changeShowContextMenu: (state): void => {
      state.showContextMenu = !state.showContextMenu;
    },
    changeShowDetails: (state): void => {
      state.showDetails = !state.showDetails;
    },
    changeShowHide: (state): void => {
      state.showHide = !state.showHide;
    },
    changeShowTrees: (state): void => {
      state.showTrees = !state.showTrees;
    },
    changeLoadedDoc: (state: any, file: FileComponent): void => {
      state.loadedDoc = file;
      if (
        (file.HasXfa === false && file.Type === FileTypes.PDF) ||
        file.Type === FileTypes.XML ||
        file.Type === FileTypes.TXT
      ) {
        state.isUnsupportedFile = false;
      }
    },
    changeLoadedPdfPath: (state, fileInfo): void => {
      state.loadedPdfPath = fileInfo;
    },
    changeTreeSearchResults: (state, results): void => {
      state.treeSearchResults = results;
    },
    resetLoadingInformation: (state): void => {
      state.loadingInformation = [false, false, false, false, false];
    },
    changeIsFullScreen: (state, isFullScreen): void => {
      state.isFullScreen = isFullScreen;
    },
    changeIsDocumentLoaded: (state, isLoaded): void => {
      if (state.nonAppActiveView == Panels.ViewerOverlay)
        state.isDocumentLoaded = false;
      else state.isDocumentLoaded = isLoaded;
    },
    changeIsSearchOpen: (state, isOpen): void => {
      state.isSearchOpen = isOpen;
    },
    changeTreeLegendState: (state): void => {
      state.treeLegendState = !state.treeLegendState;
    },
    changeOpenMenuCount: (state: any, value: boolean): void => {
      if (value) {
        state.openMenuCount++;
      } else {
        state.openMenuCount--;
      }
    },
    changeSearchText: (state: any, value: string): void => {
      state.searchText = value;
    },
    changeActiveTree: (state: any, treeType: TreeType): void => {
      state.activeTree = treeType;
    },
    changeFileToDownload: (state, filePath): void => {
      state.fileToDownload = filePath;
    },
    updateUserSettings: (
      state: any,
      userSettings: UserSettingsComponent
    ): void => {
      state.userSettings = userSettings;
    },
    updateUserRights: (state: any, userRights: UserRightsComponent): void => {
      state.userRights = userRights;
    },
    changeInventoryNodeToOpen: (state, nodeInfo): void => {
      state.inventoryNodeToOpen = nodeInfo;
    },
    changeShowOverlayMessage: (state: any, viewType: string): void => {
      state.showOverlayMessage = viewType;
      state.nonAppActiveView = Panels.ViewerOverlay;
      state.isDocumentLoaded = false;
    },
    changeRecentsLoaded: (state: any, isRecentsLoaded: boolean): void => {
      state.recentsLoaded = isRecentsLoaded;
    },
    changeUserGuideUrl: (state: any, userGuideUrl: string): void => {
      state.userGuideUrl = userGuideUrl;
    },
    changeConfigurationInfo: (state, configModel): void => {
      state.configurationInfo = configModel;
    },
    setActiveCompany: (state, company): void => {
      state.activeCompany = company;
    },
    updateUserCompanies: (state, companies): void => {
      state.companies = companies;
      state.areCompaniesLoading = false;
    },
  },
  getters: {
    companyId(state): Number {
      return (state.activeCompany as unknown as Company).Id;
    },
  }
});
