
import Vue from "vue"
import { Detail } from "../../models/adaptedDetailComponent";
import DetailsHelper from "../../helpers/detailsHelper";
import DetailItem from "./DetailItem.vue";
import UsContacts from "./UsContacts.vue";
import DetailList from "./DetailList.vue";

export default Vue.extend({
    components: {
        DetailItem,
        DetailList,
        UsContacts
    },
    data(): {
        firstBunch: string[];
        secondBunch: string[];
        thirdBunch: string[];
        fourthBunch: string[];
    } {
        return {
            firstBunch: [
                "Title",
                "Submission Title",
                "Sequence Number",
                "Submission-Unit Number",
                "Submission Number"],
            secondBunch: [
                "Submission-Unit Type",
                "Submission-Unit Title",
                "Submission Type",
                "Sequence Type",
                "Sequence Description Type",
                "Regulatory Activity In",
                "Regulatory Activity Type",
                "Regulatory Activity Lead",
                "Sequence Description",
                "State",
                "Applicant",
                "DUNS Number",
                "Sequence Date",
                "Supplement Effective Date Type",
                "Contact Name",
                "Contact Phone",
                "Contact Email",
                "Product Type",
                "Product Number",
                "Description",
                "Agency"
            ],
            thirdBunch: ["Mode", "High Level Number"],
            fourthBunch: ["DMF Holder", "DMF Number", "PMF Holder", "PMF Number", "Paragraph 13 TPA", "Article 13 TPA", "Specification Version"]
        }
    },
    methods: {
        orderedResults(data: any, sorting: string[]): Detail[] {
            return DetailsHelper.sortDetails(data, sorting,
                (x: Detail) => sorting.includes(x.Header));
        },
        getProperty(list: any, propName: string): string | null {
            const prop: any = list.InfoItems.find((x: any) => x.Header === propName);
            return prop ? prop.Content : null;
        },
        getSpecificDetail(list: any, propName: string): string | null {
            const prop: any = list.InfoItems.find((x: any) => x.Header === propName);
            return prop;
        }
    },
    props: {
        details: {
            type: Object
        },
        treeNode: {
            type: Object
        }
    }
})
