import { AgencyType, AgencyTypeCode, Region, AppType, UnitType, Version, SubType, ContactType, ContactDetailType, Contact } from "@/models/WebPublish";
import { ValidateAppNumberAndTypeRequest, CreateApplicationRequest, UpdateApplicationRequest, DeleteApplicationRequest } from "@/models/WebPublish/Requests";
import GlobalHelper from "@/helpers/globalHelper";
import ErrorHelper from "@/helpers/errorHelper";
import { BadRequestError } from "@/models/Errors";
import { ApplicationDetails } from "@/models/WebPublish/ApplicationDetails";
import { CreateSubmissionRequest } from "@/models/WebPublish/Requests/CreateSubmissionRequest";
import { UpdateSubmissionRequest } from "@/models/WebPublish/Requests/UpdateSubmissionRequest";
import { Unit } from "@/models/WebPublish";

class ApplicationsService {
  public async getRegions(): Promise<Region[]> {
    return await GlobalHelper.http(`${window.sessionUrl}Applications/GetPublishSupportedRegions`, ErrorHelper.PublishSupportedRegionsError);
  }

  public async getVersions(regionId: number): Promise<Version[]> {
    return await GlobalHelper
      .http(`${window.sessionUrl}Applications/GetPublishSupportedVersions?regionId=${regionId}`, ErrorHelper.PublishSupportedVersions);
  }

  public async getAgencyTypes(regionId: number): Promise<AgencyType[]> {
    return await GlobalHelper
      .http(`${window.sessionUrl}Applications/GetAgencyTypes?regionId=${regionId}`, ErrorHelper.AgencyTypes);
  }

  public async getAgencyTypeCodes(codeSystemId: number): Promise<AgencyTypeCode[]> {
    return await GlobalHelper
      .http(`${window.sessionUrl}Applications/GetAgencyTypeCodes?codeSystemId=${codeSystemId}`, ErrorHelper.AgencyTypeCodes);
  }

  public async getAppTypes(codeSystemId: number): Promise<AppType[]> {
    return await GlobalHelper
      .http(`${window.sessionUrl}Applications/GetAppTypes?codeSystemId=${codeSystemId}`, ErrorHelper.AppTypes);
  }

  public async getApplicants(companyId: number, startsWith: string): Promise<string[]> {
    return await GlobalHelper
      .http(`${window.sessionUrl}Submissions/GetApplicants?companyId=${companyId}&startsWith=${startsWith}`, ErrorHelper.Applicants);
  }

  public async getUnitTypes(codeSystemId: number, appTypeId: number): Promise<UnitType[]> {
    return await GlobalHelper
      .http(`${window.sessionUrl}Submissions/GetUnitTypes?codeSystemId=${codeSystemId}&appTypeId=${appTypeId}`, ErrorHelper.UnitTypes);
  }

  public async getSubTypes(codeSystemId: number, unitTypeId: number): Promise<SubType[]> {
    return await GlobalHelper
      .http(`${window.sessionUrl}Submissions/GetSubTypes?codeSystemId=${codeSystemId}&unitTypeId=${unitTypeId}`, ErrorHelper.SubTypes);
  }

  public async getAllowedUnits(companyId: number, appId: number | null, unitTypeId: number | null): Promise<Unit[]> {
    let result = [{Id: -1, Title: "Create a New Submission"}];

    if(appId && unitTypeId) {
      const units = await GlobalHelper
        .http(`${window.sessionUrl}Submissions/GetAllowedUnits?companyId=${companyId}&appId=${appId}&unitTypeId=${unitTypeId}`, ErrorHelper.AllowedUnits);
      result = result.concat(units);
    }

    return result;
  }

  public async getContactTypes(): Promise<ContactType[]> {
    return await GlobalHelper.http(`${window.sessionUrl}Contacts/GetContactTypes`, ErrorHelper.ContactTypes);
  }

  public async getContactDetailTypes(): Promise<ContactDetailType[]> {
    return await GlobalHelper.http(`${window.sessionUrl}Contacts/GetContactDetailTypes`, ErrorHelper.ContactDetailTypes);
  }

  public async getContacts(companyId: number, unitId: number, subId: number = 0): Promise<Contact[]> {
    return await GlobalHelper
      .http(`${window.sessionUrl}Contacts/GetContacts?companyId=${companyId}&unitId=${unitId}&subId=${subId}`, ErrorHelper.Contacts);
  }

  public async getContactOrganizations(companyId: number, startsWith: string): Promise<string[]> {
    return await GlobalHelper
      .http(`${window.sessionUrl}Contacts/GetOrganizations?companyId=${companyId}&startsWith=${startsWith}`, ErrorHelper.ContactOrganizations);
  }

  public async validateAppNumberAndType(companyId: number, request: ValidateAppNumberAndTypeRequest): Promise<boolean> {
    const response = await fetch(`${window.sessionUrl}Applications/ValidateAppNumberAndType`, {
      method: "POST",
      body: JSON.stringify({ companyId, request }),
      headers: {
        "Content-Type": "application/json",
      }
    });

    if (response.ok) {
      return await response.text() === "true";
    } else {
      const errorMessage = JSON.parse(await response.text());
      throw Error(errorMessage);
    }
  }

  public async createApplication(companyId: number, request: CreateApplicationRequest): Promise<number> {
    const response = await fetch(`${window.sessionUrl}Applications/CreateApplication`, {
      method: "POST",
      body: JSON.stringify({ companyId, request }),
      headers: {
        "Content-Type": "application/json",
      }
    });

    if (response.ok) {
      return Number.parseInt(await response.text());
    }
    else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400: throw new BadRequestError(errorMessage);
        default: throw Error(errorMessage);
      }
    }
  };

  public async deleteApplication(request: DeleteApplicationRequest): Promise<void> {
    const response = await fetch(`${window.sessionUrl}Applications/DeleteApplication`, {
      method: "POST",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
      }
    });

    if (!response.ok) {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400: throw new BadRequestError(errorMessage);
        default: throw Error(errorMessage);
      }
    }
  };

  public async publishSubmissionUnit(companyId: number, appId: number, subId: number): Promise<void> {
    const response = await fetch(`${window.sessionUrl}Submissions/PublishSubmissionUnit`, {
      method: "POST",
      body: JSON.stringify({ companyId, appId, subId }),
      headers: {
        "Content-Type": "application/json",
      }
    });

    if (!response.ok) {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400: throw new BadRequestError(errorMessage);
        default: throw Error(errorMessage);
      }
    }
  };

  public async deleteSubmissionUnit(companyId: number, appId: number, subId: number): Promise<void> {
    const response = await fetch(`${window.sessionUrl}Submissions/DeleteSubmission`, {
      method: "POST",
      body: JSON.stringify({ companyId, appId, subId }),
      headers: { "Content-Type": "application/json" }
    });

    if (!response.ok) {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400: throw new BadRequestError(errorMessage);
        default: throw Error(errorMessage);
      }
    }
  };

  public async updateApplication(companyId: number, request: UpdateApplicationRequest): Promise<void> {
    const response = await fetch(`${window.sessionUrl}Applications/UpdateApplication`, {
      method: "POST",
      body: JSON.stringify({ companyId, request }),
      headers: {
        "Content-Type": "application/json",
      }
    });

    if (!response.ok) {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400: throw new BadRequestError(errorMessage);
        default: throw Error(errorMessage);
      }
    }
  };

  public async getApplicationDetails(companyId: number, appId: number): Promise<ApplicationDetails> {
    return await GlobalHelper
      .http(`${window.sessionUrl}Applications/GetApplicationDetails?companyId=${companyId}&appId=${appId}`, ErrorHelper.AppDetails);
  }

  public async createSubmission(companyId: number, appId: number, request: CreateSubmissionRequest): Promise<void> {
    if(request.UnitId == -1) request.UnitId = null;
    const response = await fetch(`${window.sessionUrl}Submissions/createSubmission`, {
      method: "POST",
      body: JSON.stringify({ companyId, appId, request }),
      headers: {
        "Content-Type": "application/json",
      }
    });

    if (!response.ok) {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400: throw new BadRequestError(errorMessage);
        default: throw Error(errorMessage);
      }
    }
  };

  public async updateSubmission(companyId: number, appId: number, request: UpdateSubmissionRequest): Promise<void> {
    if(request.UnitId == -1) request.UnitId = null;
    const response = await fetch(`${window.sessionUrl}Submissions/updateSubmission`, {
      method: "POST",
      body: JSON.stringify({ companyId, appId, request }),
      headers: {
        "Content-Type": "application/json",
      }
    });

    if (!response.ok) {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400: throw new BadRequestError(errorMessage);
        default: throw Error(errorMessage);
      }
    }
  };
}

export const applicationsService = new ApplicationsService();
