
import Vue from "vue"
import DetailsHelper from '../../helpers/detailsHelper';
import CopyButton from './CopyButton.vue';
import GlobalHelper from "../../helpers/globalHelper";
export default Vue.extend({
    components: {
      CopyButton
    },
    data(): {
        reuses: any;
    } {
        let currentAppNumber: string | null = null;
        const indexOfCurrentApp = this.reuseInfo.map((a: {InCurrentApp: boolean})=>a.InCurrentApp).indexOf(true);
        if (indexOfCurrentApp != -1)
            currentAppNumber = this.reuseInfo[indexOfCurrentApp].AppNumber;
        const groupedData = DetailsHelper.makeArrayNestedByPropertyName(this.reuseInfo, "AppNumber");
        const sortedData = DetailsHelper.sortCurrentAppFirst(groupedData, currentAppNumber);
        return {
            reuses: sortedData
        }
    },
    mounted(): void {
        const dividers = document.querySelectorAll("#detailsCard > .pa-3 > .v-divider");
        let lastDivider = dividers[dividers.length - 1];
        lastDivider.parentElement!.removeChild(lastDivider);
    },
    props: {
        reuseInfo: {
            type: [Array, Object]
        }
    },
    methods: {
        getActionTypeImage(actionTypeId: number): string | null {
          switch (actionTypeId) {
            case 0:
            case 1:
            case 5:
            case 6:
              // @ts-ignore
              return GlobalHelper.getPath(require("../../Content/images/treeicons/ico-reused-new-file-a.svg"));
            case 2:
              // @ts-ignore
              return GlobalHelper.getPath(require("../../Content/images/treeicons/ico-reused-delete-file-a.svg"));
            case 3:
              // @ts-ignore
              return GlobalHelper.getPath(require("../../Content/images/treeicons/ico-reused-replace-file-a.svg"));
            case 4:
              // @ts-ignore
              return GlobalHelper.getPath(require("../../Content/images/treeicons/ico-reused-append-file-a.svg"));
          }
          return null;
        },
        getActionTypeWord(actionTypeId: number): string | null {
            const word = DetailsHelper.getActionTypeWord(actionTypeId);
            return word;
        }
    }
})
