import TreeComponent from '../models/treeComponent';
import GlobalHelper from './globalHelper';

export class TreeHelper {
  public static makeTreeComponent(treeNode: any): TreeComponent {
    const treeComponent = new TreeComponent();
    treeComponent.Id = treeNode.get('Id');
    treeComponent.ReviewSectionId = treeNode.get('ReviewSectionId');
    treeComponent.HasChildren = treeNode.get('HasChildren');
    treeComponent.Expanded = treeNode.get('Expanded');
    treeComponent.Title = treeNode.get('Title');
    treeComponent.ParentId = treeNode.get('ParentId');
    treeComponent.UnitId = treeNode.get('UnitId');
    treeComponent.SubId = treeNode.get('SubId');
    treeComponent.SectionId = treeNode.get('SectionId');
    treeComponent.RepeatableSectionId = treeNode.get('RepeatableSectionId');
    treeComponent.SectionExtensionId = treeNode.get('SectionExtensionId');
    treeComponent.FileUseSetId = treeNode.get('FileUseSetId');
    treeComponent.FileUseHistoryId = treeNode.get('FileUseHistoryId');
    treeComponent.FileUseStateId = treeNode.get('FileUseStateId');
    treeComponent.FileIconImageUrl = treeNode.get('FileIconImageUrl');
    treeComponent.IsFiltered = treeNode.get('IsFiltered');
    treeComponent.IsEmptyAllTheWayDown = treeNode.get('IsEmptyAllTheWayDown');
    treeComponent.InRepeatGroup = treeNode.get('InRepeatGroup');
    treeComponent.ActionType = treeNode.ActionType;
    treeComponent.IsGrouped = treeNode.IsGrouped;
    treeComponent.IsReused = treeNode.IsReused;
    treeComponent.TreeTypeId = treeNode.TreeTypeId;
    treeComponent.NodeTypeId = treeNode.NodeTypeId;
    treeComponent.FileType = treeNode.FileType;
    treeComponent.SpecId = treeNode.SpecId;
    treeComponent.SubDisplayOrder = treeNode.SubDisplayOrder;
    treeComponent.DoesExist = treeNode.DoesExist;
    treeComponent.IsPlaceholder = treeNode.IsPlaceholder;
    treeComponent.HasXfa = treeNode.HasXfa;
    return treeComponent;
  }

  public static getTreeTypeId(tree: string): number {
    switch (tree) {
      case 'Lifecycle':
        return 1;
      case 'Current':
        return 2;
      case 'Activities':
        return 3;
      case 'Sequence':
        return 4;
      case 'Origin':
        return 5;
      case 'Search':
        return 6;
      default:
        return 0;
    }
  }

    public static findKidsAndMakeOrnamentHolder(
        element: any,
        treeId: string,
        isWebPublish: boolean,
        tree: kendo.ui.TreeView
    ): void {
        const tops = element.querySelectorAll(".k-top") as NodeList;
        const mids = element.querySelectorAll(".k-mid") as NodeList;
        const bots = element.querySelectorAll(".k-bot") as NodeList;

        this.makeOrnamentHolder(tops, treeId, isWebPublish, tree);
        this.makeOrnamentHolder(mids, treeId, isWebPublish, tree);
        this.makeOrnamentHolder(bots, treeId, isWebPublish, tree);
    }

    private static makeOrnamentHolder(elements: NodeList, treeId: string, isWebPublish: boolean, tree: kendo.ui.TreeView): void {
        const elementCount = elements.length;

        for (let i = 0; i < elementCount; i += 1) {
            const node = (elements[i] as HTMLElement);

            if (node.innerHTML.indexOf("ornamentHolder") === -1 && node.querySelectorAll(".no-info").length == 0){
                if (treeId === "search-result-tree"){
                    const group = node.closest('.k-group');
                    if(group && group.getAttribute("type") === "ectd-tree"){
                        this.addOrnamentClasses(node, "ornamentHolder ornamentHolderSearch", "ornamentButton ornamentButtonSearch", treeId);
                    }
                    else{
                        this.addOrnamentClasses(node, "ornamentHolder", "ornamentButton", treeId);
                    }
                }
                else{
                    this.addOrnamentAndMenu(node,"ornamentHolder", "ornamentButton", treeId, isWebPublish, tree);
                }
            }

            this.changeIcon(node, "k-i-expand");
            this.changeIcon(node, "k-i-collapse");
        }
    }

    private static changeIcon(node: HTMLElement, iconName: string) {
        const wrongExpandIcon = `<span class=\"k-icon ${iconName}\">`;
        const expandIcon = `<span class=\"k-icon ${iconName}\"></span>`;

        if(node.innerHTML.indexOf(wrongExpandIcon) >= 0 && node.innerHTML.indexOf(expandIcon) === -1) {
            node.innerHTML = node.innerHTML.replace(wrongExpandIcon, expandIcon);
        }
    }

    private static infoUrl(): string {
      // @ts-ignore
      return GlobalHelper.getPath(require('../Content/images/info-black.svg'));
    }

    private static uploadUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../Content/images/icon-upload.svg"));
    }

    private static addOrnamentClasses(node: HTMLElement, ornamentClass: string, ornamentButtonClass: string, treeId: string): void {
        node.innerHTML =
                '<div class="' + ornamentClass +
                '"><span class="' +  ornamentButtonClass +
                '" onclick="window.infoClick(this);" treeId="' +
                treeId +
                '"><img src="' + this.infoUrl() + '"/></span></div>' +
                node.innerHTML;
    }

    private static showUpload(treeId: string, nodeType: number, isWebPublish: boolean): boolean
    {
      return isWebPublish && nodeType == 0 && ['sequence-tree', 'origin-tree','regulatoryactivity-tree'].includes(treeId);
    }

    private static addOrnamentAndMenu(node: HTMLElement, ornamentClass: string, ornamentButtonClass: string, treeId: string, isWebPublish: boolean, tree: kendo.ui.TreeView): void {
        // @ts-ignore
        const uid = node.parentNode.attributes['data-uid'];
        let nodeType = null;
        if(uid != null) {
          const element = tree.findByUid(uid.value);
          const dataItem = tree.dataItem(element) as any;
          nodeType = dataItem.NodeType;
        }
        //mdi-format-vertical-align-top
        node.innerHTML =
                `<div class="${ornamentClass}">
                    <span onmouseover="window.onHover(this, 'Details')" onmouseout="window.offHover('Details')" class="k-icon ${ornamentButtonClass}" onclick="window.infoClick(this);" treeId="${treeId}">
                        <img src="${this.infoUrl()}" style="margin-top: -1px;"/>
                    </span>`
                + (
                    this.showUpload(treeId, nodeType, isWebPublish) ?
                    `<span onmouseover="window.onHover(this, 'Publish Submission')" onmouseout="window.offHover('Publish Submission')" onclick="window.publishClick(this)" class="k-icon ${ornamentButtonClass}" treeId="${treeId}">
                        <img src="${this.uploadUrl()}" style="margin-top: -1px;"/>
                    </span>`
                    : ''
                ) +
                    `<span class="black--text k-icon k-i-more-vertical ${ornamentButtonClass}" onclick="window.menuClick(this)" treeId="${treeId}"></span>
                </div>
                ${node.innerHTML}`;
    }
}
