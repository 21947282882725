import ComponentService from '../services/componentService';
import { TreeHelper } from './treeHelper';
import ErrorHelper from './errorHelper';
import ClipboardHelper from './clipboardHelper';
import { EventBus } from './EventBus';

export class LinkToShareHelper {
  public static copyLink(treeNode: any, companyId: number): void {
    const treeComponent = TreeHelper.makeTreeComponent(treeNode);
    const url = `LinkToShare/CopyLink?companyId=${companyId}`;
    new ComponentService().getLinkToShare(treeComponent, this.copyLinkSuccess, url);
  }

  public static copyLinkSuccess(result: any): void {
    if (ClipboardHelper.addToClipboard(result)) {
      ErrorHelper.addSnackbarMessage('Link copied to clipboard!', 'success');
    } else {
      // catch in CopyFailedAlert.
      EventBus.$emit('changeFailedLinkToShare', result);
    }
  }
}
