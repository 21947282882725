
import Vue from "vue";
import ClipboardHelper from '../../helpers/clipboardHelper';
import ErrorHelper from '../../helpers/errorHelper';

export default Vue.extend({
    props: {
        detail: String,
        smallButton: {
          type: Boolean,
          default: true
        },
        notHovered: Boolean,
        hovered: Boolean
    },
    methods: {
        copyDetail() {
            if (ClipboardHelper.addToClipboard(this.detail)){
              ErrorHelper.addSnackbarMessage("Copied to clipboard.", "success");
            } else {
              ErrorHelper.addSnackbarMessage("Copy failed. Please try again.", "error");
            }
        }
    }
});
