import { ContextMenuItemKey } from "@/enums";

export class ContextMenuItem {
  constructor(
    public key: ContextMenuItemKey,
    public displayText: string,
    public icon: string = "",
    public disabled: boolean = false
  ) {
    this.isDivider = key === ContextMenuItemKey.divider;
  }

  public readonly isDivider: boolean;
}