import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#0973b6",
                info: "#0973b6"
            }
        }
    },
    icons: {
        iconfont: "mdi",
    },
});