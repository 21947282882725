import { ContextMenuItemKey } from '@/enums';
import { NodeType } from '../helpers/Enums';
import { ContextMenuItem } from '@/models';

class TreeContextMenuBuilder {
  public build(isPublishMode: boolean, treeNode: any): ContextMenuItem[] {
    const result: ContextMenuItem[] = [];

    const nodeType: NodeType = treeNode.NodeTypeId;

    result.push(new ContextMenuItem(ContextMenuItemKey.copyLink, 'Copy Link', 'mdi-content-copy'));

    if(treeNode.FileUseHistoryId && treeNode.DoesExist)
      result.push(new ContextMenuItem(ContextMenuItemKey.downloadFile, 'Download', 'mdi-format-vertical-align-bottom'));

    if (!isPublishMode) {
      return result;
    }

    const publishItems = [];

    switch (nodeType) {
      case NodeType.Sub:
        publishItems.push(new ContextMenuItem(ContextMenuItemKey.editSubmission, 'Edit Submission Unit', 'mdi-pencil'));
        publishItems.push(new ContextMenuItem(ContextMenuItemKey.publishSubmission, 'Publish Submission Unit', 'mdi-publish'));
        publishItems.push(new ContextMenuItem(ContextMenuItemKey.divider, 'Divider'));
        publishItems.push(new ContextMenuItem(ContextMenuItemKey.deleteSubmission, 'Delete Submission Unit', 'mdi-delete'));
        break;
      case NodeType.Section:
      case NodeType.RepeatSection:
      case NodeType.SectionExtension:
        if (treeNode.AllowFiles) {
          publishItems.push(new ContextMenuItem(ContextMenuItemKey.addFiles, 'Add File(s)', 'mdi-plus'));
        }
        break;
      case NodeType.FileUseHistory:
      case NodeType.FileUseSet:
        publishItems.push(new ContextMenuItem(ContextMenuItemKey.editFileTitle, 'Edit File Title', 'mdi-pencil'));
        publishItems.push(new ContextMenuItem(ContextMenuItemKey.editFileName, 'Edit File Name', 'mdi-pencil'));
        publishItems.push(new ContextMenuItem(ContextMenuItemKey.deleteFile, 'Delete File', 'mdi-delete'));
        break;
      default:
        break;
    }

    if (publishItems.length) {
      result.push(new ContextMenuItem(ContextMenuItemKey.divider, 'Divider'));
      result.push(...publishItems);
    }

    return result;
  }
}

export const treeContextMenuBuilder = new TreeContextMenuBuilder();
