import $ from "jquery";
import ErrorHelper from "./errorHelper";

// class to be used for functional methods with no specific owner
export default class GlobalHelper {
  public static IsIe(): boolean {
    const ua: string = window.navigator.userAgent;
    const msie: number = ua.indexOf('MSIE ');
    return msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./);
  }

  public static encodeData(data: any): object {
    return JSON.parse(JSON.stringify(data), (key, value): string =>
      typeof value === 'string' ? encodeURI(value) : value,
    );
  }

  public static getPath(relativePath: string) {
    return ((window.sessionUrl || '').replace(/[0-9,a-fA-F]{8}\//,'') + relativePath).replace(/\/+/g, "/");
  }

  public static enqueueUpdateApplication(forceUpdateAllEnxP: boolean = false) {
    $.ajax({
      method: "POST",
      cache: false,
      url: "./ValidateMessageQueue/EnqueueUpdateApplication",
      data: {
        forceUpdateAllEnxP,
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      success: function (result) {
      },
      error: function (result, errorText, errorDescription) {
        ErrorHelper.addSnackbarMessage(`${result.responseText || errorDescription || ''}`, 'error');
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      complete: function (result) {
      }
    });
  }

  public static async http(request: RequestInfo, errorMessage: string): Promise<any> {
    return new Promise(resolve => {
      fetch(request, { method: "GET" })
          .then((res) => {
            if (res.status === 500) {
              ErrorHelper.addSnackbarMessage(errorMessage, "error", res.statusText, true);
              return "";
            }
            return res.text();
          })
          .then((text) => text.length ? JSON.parse(text) : null)
          .then(body => resolve(body));
    });
  };
}
