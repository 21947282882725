import "url-polyfill";
import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import App from "@/App.vue";
import { EventBus } from "@/helpers/EventBus";
import PdfViewerControl from "@/pdf/pdfviewer-control";
import PdfViewerGlobals from "@/pdf/pdfviewer-globals";
import store from "@/store";
import router from "@/router";
import PdfTron from "certara-vue-pdftron-viewer";
import "@/interceptors";
//TODO: move the kendo import to components.
import "@progress/kendo-ui/js/kendo.grid.js";
import "@progress/kendo-ui/js/kendo.treeview.js";
import $ from 'jquery';
import {initWindow} from "@/helpers/window";
import init from "common-vue-components/init";
import OktaVue from "common-vue-components/oktaVue";
import OktaVueCookie from "common-vue-components/plugins/OktaVueCookie";
import Router from "vue-router";

declare global {
  interface Window {
    previewPane: any;
    returnUrl: string;
    errorMessage: string;
    PdfViewerGlobals: PdfViewerGlobals;
    clipboardData: any;
    baseUrl: string;
    sessionUrl: string;
    version: string;
    infoClick: any;
    menuClick: any;
    publishMode: string;
  }

  interface Document {
    fullscreenElement: any;
    msFullscreenElement: any;
    mozFullScreenElement: any;
    webkitFullscreenElement: any;
    onmsfullscreenchange: any;
    onmozfullscreenchange: any;
    onwebkitfullscreenchange: any;
  }
}

init();

const KEEP_ALIVE_TIMEOUT = 60 * 1000;
setInterval(() => {
  $.get('./Main/KeepAlive');
}, KEEP_ALIVE_TIMEOUT);

function emitClickEvent(eventName: string, senderElement: any, parentElement: HTMLElement): void {
  const coordinates = parentElement.getBoundingClientRect();
  const treeNode = senderElement.parentElement.parentElement.querySelectorAll(
    ".tree-item"
  );
  const treeId = senderElement.getAttribute("treeId");
  EventBus.$emit(eventName, treeNode, treeId, coordinates);
}

function removeHighlights(oldNode: Element): void {
  oldNode.classList.remove("grey", "lighten-3");
  oldNode.removeAttribute("id");

}

window.infoClick = (sender: any): void => {
  const oldNode = document.querySelector("#selectedDetailsNode");
  const parent = sender.parentElement.parentElement as HTMLElement;

  if (oldNode) {
    removeHighlights(oldNode);
  }

  if (oldNode === parent)
    return;

  parent.classList.add("grey", "lighten-3");
  parent.setAttribute("id", "selectedDetailsNode");
  emitClickEvent("infoClick", sender, parent);
};

window.menuClick = (sender: any): void => {
  const oldNode = document.querySelector("#selectedContextNode");
  const parent = sender.parentElement.parentElement as HTMLElement;

  if (oldNode) {
    removeHighlights(oldNode);
  }

  if (oldNode === parent) {
    return;
  }

  parent.classList.add("grey", "lighten-3");
  parent.setAttribute("id", "selectedContextNode");
  emitClickEvent("menuClick", sender, parent);
}

window.PdfViewerGlobals = PdfViewerGlobals;
initWindow(PdfViewerGlobals);
PdfViewerControl.register(store, PdfTron,
  {
    config: "./Content/pdftron/viewer_config.js",
    documentType: "pdf",
    enableAnnotations: true,
    enableReadOnlyMode: true,
    hideAnnotationPanel: false,
    path: "./Content/pdftron",
    showPageHistoryButtons: true,
    showToolbarControl: true,
    streaming: false,
    disabledElements: ["header"],
    css: "./Content/pdftron/PreviewPane/PreviewPane.css"
  });

Vue.use(Router);
Vue.use(OktaVueCookie);
Vue.use(OktaVue);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#mainContent");
