
import Vue from "vue"
import CopyButton from './CopyButton.vue';
import DetailsHelper from '../../helpers/detailsHelper';
export default Vue.extend({
    components: {
      CopyButton
    },
    data(): {
        regActs: any;
    } {
        const regActDetails = this.infoItems.find((x: any) => x.Header === "Regulatory Activities").Content
        const groupedData = DetailsHelper.makeArrayNestedByPropertyName(regActDetails, "DisplayAppNumber");
        return {
            regActs: groupedData
        }
    },
    mounted(): void {
        const dividers = document.querySelectorAll("#detailsCard > .pa-3 > .v-divider");
        let lastDivider = dividers[dividers.length - 1];
        lastDivider.parentElement!.removeChild(lastDivider);
    },
    props: {
        infoItems: {
            type: [Array, Object]
        }
    }
})
