
import Vue from "vue";
import Info from "../dropdowns/Info.vue";
import NineDot from "common-vue-components/components/Dropdowns/NineDot.vue";

export default Vue.extend({
    components: {
        Info,
        NineDot
    },
    data(): {
        drawer: boolean;
    } {
        return {
            drawer: false
        };
    },
    methods: {
        signOut(): void {
            location.href = "./Main/SignOut";
        }
    },
    props: {
        drawerState: {
            type: Boolean,
            default: false
        },
        userGuideUrl: {
            type: String,
            required: true
        },
        webApplicationName: {
            type: String,
            required: true
        },
    },
    watch: {
        drawerState(): void {
            this.drawer = this.drawerState;
        },
        drawer(): void {
            if (this.drawer === false) {
                this.$emit("changeDrawerState");
            }
        }
    }
});
