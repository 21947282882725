import { render, staticRenderFns } from "./EditSubmissionCard.vue?vue&type=template&id=44ce5ae9&scoped=true&"
import script from "./EditSubmissionCard.vue?vue&type=script&lang=ts&"
export * from "./EditSubmissionCard.vue?vue&type=script&lang=ts&"
import style0 from "./EditSubmissionCard.vue?vue&type=style&index=0&id=44ce5ae9&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44ce5ae9",
  null
  
)

export default component.exports