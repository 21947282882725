import NavigationHistory from './navigationHistory';
import LmtdDblLinkedList from '../helpers/lmtd-dbl-linked-list';
import { FileTypes } from '../helpers/Enums';
import { EventBus } from '../helpers/EventBus';

export default class HistoryTracker {
  private list = new LmtdDblLinkedList<NavigationHistory>(28);

  public constructor() {
    this.updateHistoryButtons();
  }

  public add(history: NavigationHistory): void {
    this.list.add(history);
    this.updateHistoryButtons();
  }

  public addIfDifferentPage(history: NavigationHistory): void {
    const current = this.list.currentData();
    if (history.filename === undefined) return;
    if (current === null || !current.isSamePageAs(history.dest)) {
      this.add(history);
    } else {
      current.updateScroll(history.dest);
    }
  }

  public addIfDifferentFile(history: NavigationHistory): void {
    const current = this.list.currentData();
    if (!current || current.fuhId != history.fuhId) {
      this.add(history);
    }
  }

  public addPlaceholder(type: FileTypes | null): void {
    const placeholder = new NavigationHistory(-1, null, null, '', type, true);
    this.list.add(placeholder, true);
    this.updateHistoryButtons();
  }

  public goForward(): NavigationHistory | null {
    const next = this.list.goForward();
    this.updateHistoryButtons();
    return next;
  }

  public goBack(): NavigationHistory | null {
    const prev = this.list.goBack();
    this.updateHistoryButtons();
    return prev;
  }

  public clear(): void {
    this.list.clear();
    this.updateHistoryButtons();
  }

  private updateHistoryButtons(): void {
    // catch in XmlViewer.
    EventBus.$emit('changeCanGoBack', this.list.canGoBack());
    EventBus.$emit('changeCanGoForward', this.list.canGoForward());
  }
}

export const ViewHistory = new HistoryTracker();
