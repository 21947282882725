
import Vue from "vue";
import Dialog from "../popups/Dialog.vue";
import TreeComponent from "@/models/treeComponent";
import ErrorHelper from "@/helpers/errorHelper";
import { mapGetters, mapMutations, mapState } from "vuex";
import { applicationsService } from "@/services";
import { BadRequestError } from "@/models/Errors";

export default Vue.extend({
  beforeUpdate() {
    this.submissionUnitsCount = this.treeView?.dataSource?.total() ?? 0;
  },
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Dialog
  },
  props: {
   value: { type: Boolean },
   treeNode: { type: Object, default: null },
   treeView: { type: Object },
  },
  data(): {
    deleting: boolean;
    submissionUnitsCount: number;
  } {
    return {
      deleting: false,
      submissionUnitsCount: 0,
    };
  },
  computed: {
    ...mapGetters(["companyId"]),
    ...mapState(["selectedApplicationId"]),
    message(): string {
      return this.submissionUnitsCount > 1 
        ? "This will delete your submission-unit along with its content(s)."
        : "Since this is the last submission-unit, the application will be deleted along with the submission-unit.";
    }
  },
  methods: {
    ...mapMutations(["changeAppSelector", "changeSelectedNode"]),
    async deleteSubmissionUnit(): Promise<void> {
      this.deleting = true;

      try {
        if (this.submissionUnitsCount === 1) {
          await applicationsService.deleteApplication({
            AppId: this.selectedApplicationId,
            CompanyId: this.companyId});
          this.changeAppSelector();
        } else {
          await applicationsService.deleteSubmissionUnit(
            this.companyId,
            this.selectedApplicationId,
            this.treeNode.SubId);
          await this.removeSubUnitFromTheTree();
        }

        ErrorHelper.addSnackbarMessage('Submission-unit has been deleted successfully.', 'success');
      }
      catch (e) {
        if (e instanceof BadRequestError) {
          ErrorHelper.addSnackbarMessage(e.message, 'error', '');
        } else {
          ErrorHelper.addSnackbarMessage('Delete was unsuccessful. Please try again or contact your System Administrator if the issue persists.', 'error', (e as Error).message, true);
        }
      }
      finally {
        this.deleting = false;
        this.$emit('input', false);
      }
    },
    async removeSubUnitFromTheTree(): Promise<void> {
      const treeItem = this.treeView.findByUid(this.treeNode.uid);
      this.changeSelectedNode(new TreeComponent());
      await new Promise(resolve => setTimeout(resolve, 0));
      this.treeView.remove(treeItem);
    },
  }
});
