
import Vue from "vue";
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import Dialog from "./Dialog.vue";
import { filesService } from "@/services";
import ErrorHelper from "@/helpers/errorHelper";

export default Vue.extend({
    components: {
      // eslint-disable-next-line vue/no-reserved-component-names
      Dialog
    },
    props: {
      open: { type: Boolean },
      treeNode: { type: Object, default: null },
    },
    data(): {
      deleting: boolean;
    } {
      return {
        deleting: false,
      };
    },
    computed: {
      ...mapGetters(["companyId"]),
      ...mapState(["selectedApplicationId"]),
      fileName(): string {
        return this.treeNode?.FileName;
      }
    },
    methods: {
      async deleteFile() {
        this.deleting = true;
        
        try {
          await filesService.deleteFile(this.companyId, this.selectedApplicationId, this.treeNode.SubId, this.treeNode.FileUseHistoryId);
          ErrorHelper.addSnackbarMessage('File has been deleted successfully.', 'success');
          this.close(true);
        } catch {
          ErrorHelper.addSnackbarMessage("Delete file failed", 'error', '', true);
          this.close(false);
        }
        
        this.deleting = false;
      },
      close(deleted: boolean) {
        this.$emit("close", deleted);
      }
    }
  });
