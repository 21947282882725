export enum ContactType {
  Unspecified0 = 0,
  BusinessTelephoneNumber = 1,
  FaxNumber = 2,
  MobileNumber = 3,
  Email4 = 4,
  Unspecified5 = 5,
  TelephoneNumber = 6,
  Email7 = 7,
  GivenName = 8,
  FamilyName = 9,
  MiddleName = 10,
  MiddleInitial = 11,
  Organization = 12
}
