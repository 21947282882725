
import Vue from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import { filesService } from "@/services";
import ErrorHelper from "@/helpers/errorHelper";
import { InputValidationRules } from "vuetify";
import { BadRequestError } from "@/models/Errors";

export default Vue.extend({
  props: {
    open: Boolean,
    treeNode: { type: Object, default: null },
  },
  computed: {
    ...mapGetters(["companyId"]),
    ...mapState(["selectedApplicationId"]),
    nameRules(): InputValidationRules {
      return this.nameError ? [this.nameError] : [];
    }
  },
  data(): {
    name: string;
    nameError: string;
    submitting: boolean;
  } {
    return {
      name: "",
      nameError: "",
      submitting: false,
    };
  },
  watch: {
    open(value) {
      if (value) {
        this.name = this.treeNode.FileName;
      }
    },
    name: function() {
      this.nameError = "";
    }
  },
  methods: {
    ...mapMutations(["refresh"]),
    async setName() {
      this.name = (this.name || "").trim();
    },
    async changeFileName() {
      this.submitting = true;

      try {
        await filesService.changeFileName(this.companyId, {
          FileUseHistoryId: this.treeNode.FileUseHistoryId,
          AppId: this.selectedApplicationId,
          SubId: this.treeNode.SubId,        
          ReviewSectionId: this.treeNode.ReviewSectionId,
          Name: this.name
        });
        ErrorHelper.addSnackbarMessage('The file name has been changed successfully.', 'success');
        this.$emit("close", true);
      } catch (error) {
        if (error instanceof BadRequestError) {
          this.nameError = error.message;
          return;
        }
        
        ErrorHelper.addSnackbarMessage("Changing the file name failed", "error", "", true);
        this.$emit("close", false);
      } finally {
        this.submitting = false;
      }
    },
    closeDialog() {
      this.$emit("close");
    }
  },
});
