
import Vue from "vue";
import { Panels } from "../../helpers/Enums";
import Info from "../dropdowns/Info.vue";
import NineDot from "common-vue-components/components/Dropdowns/NineDot.vue";
import CompanySelector from "common-vue-components/components/Dropdowns/CompanySelector.vue";
import AppDetails from "../details/AppDetails.vue";
import GlobalHelper from "../../helpers/globalHelper";
import { mapState, mapActions } from "vuex";
import {ModalWindow} from "@/enums";
import { RootMutations } from "@/store";
require("../../Content/Common/Common.css")

export default Vue.extend({
    components: {
        Info,
        NineDot,
        AppDetails,
        CompanySelector
    },
    data(): {
        info: boolean;
        nineDot: boolean;
        openApp: boolean;
        tooltip: string;
        treesVisible: boolean;
        version: string;
        appinfo: boolean;
        userName: string;
        showCertaraLogo: boolean;
    } {
        return {
            info: false,
            nineDot: false,
            openApp: false,
            tooltip: "File Preview",
            treesVisible: true,
            version: "",
            appinfo: false,
            userName: "",
            showCertaraLogo: true
        };
    },
    methods: {
      ...mapActions(["changeActiveCompany"]),
      appDisplayName(): string {
        const words = this.appTitle.split(" ");
        return words[words.length - 1];
      },
      showHideAppSelector(): void {
        if (this.hideAppSelectorAndHasTitle) {
          setTimeout(() => this.$emit("changeAppSelector"), 500);
        }
      },
      showHideDrawer(): void {
        this.$emit("changeDrawerState");
      },
      signOut(): void {
        this.$auth.signOut();
      },
      editApplication() {
        this.$store.commit(RootMutations.openModalWindow, ModalWindow.editApplication);
      },
      deleteApplication() {
        this.$store.commit(RootMutations.openModalWindow, ModalWindow.deleteApplication);
      }
    },
    async mounted(): Promise<void> {
        this.version = window.version;
        const user = await this.$auth.getUser();
        this.userName = user.name ?? "";
    },
    props: {
        showAppSelector: {
            type: Boolean,
            required: true
        },
        showTrees: {
            type: Boolean,
            required: true
        },
        appTitle: {
            type: String,
            required: true
        },
        nonAppActiveView: {
            type: Number
        },
        userGuideUrl: {//Info
            type: String,
            required: true
        },
        showAppDetails: {//AppDetails
            type: Boolean
        },
        webApplicationName: String,
        isWebPublish: Boolean,
        accessDenied: Boolean
    },
    watch: {
        nonAppActiveView(): void {
            this.tooltip = this.nonAppActiveView === Panels.Viewer ? "File Preview" : "Inventory";
        },
        treesVisible(): void {
            if (this.treesVisible !== this.showTrees) {
                this.$emit("changeShowTrees");
            }
        },
        nineDot(): void {
            this.$emit("changeOpenMenuCount", this.nineDot);
        },
        info(): void {
            this.$emit("changeOpenMenuCount", this.info);
        },
        appinfo(): void {
            this.$emit("changeOpenMenuCount", this.appinfo);
        }
    },
    computed: {
      ...mapState(["companies", "activeCompany", "areCompaniesLoading"]),
      hideAppSelectorAndHasTitle(): boolean {
        return this.appTitle !== '' && !this.showAppSelector;
      },
      certaraLogoUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/certara-logo.png"));
      },
      infoUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/info.svg"));
      },
      arrowUrl(): string {
        // @ts-ignore
        return this.showCertaraLogo ? GlobalHelper.getPath(require("../../Content/images/arrow_drop_up.svg")) : GlobalHelper.getPath(require("../../Content/images/arrow_drop_down.svg"));
      },
      logoutUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/icon-logout.svg"));
      },
      homeUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/icon-home.svg"));
      }
    }
});
