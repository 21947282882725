export enum TreeType {
  Lifecycle = 1,
  Current = 2,
  Activities = 3,
  Sequence = 4,
  Origin = 5,
  Search = 6,
}

export enum NodeType {
  Sub = 0,
  Unit = 1,
  Section = 2,
  RepeatSection = 3,
  SectionExtension = 4,
  FileUseSet = 5,
  FileUseHistory = 6,
}

export enum TreeType {
  activities,
  current,
  lifecycle,
  origin,
  sequence
}

export enum Panels {
  Viewer = 1,
  Details = 2,
  AppSelector = 3,
  XmlViewer = 4,
  TxtViewer = 5,
  Error = 6,
  ViewerOverlay = 7,
  ImageViewer = 8,
}

export enum ActionTypes {
  New = 1,
  Delete = 2,
  Replace = 3,
  Append = 4,
  Updated = 5,
  Link = 6,
}
export enum FileTypes {
  Other = 0,
  XML = 1,
  PDF = 2,
  JPG = 3,
  PNG = 4,
  GIF = 5,
  SVG = 6,
  SAS = 7,
  XPT = 8,
  DAT = 9,
  TXT = 10,
  DOCX = 11,
  JPEG = 12
}
