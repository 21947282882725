
import $ from "jquery";
import Vue from "vue";
import { ActionTypes, FileTypes, NodeType } from "../../helpers/Enums";
import { TreeHelper } from "../../helpers/treeHelper";
import TreeComponent from "../../models/treeComponent";
import ComponentService from "../../services/componentService";
import DetailsHelper from "../../helpers/detailsHelper";
import DetailItem from "./DetailItem.vue";
import LifeCycleDetailItem from "./LifeCycleDetailItem.vue";
import RegulatoryActivities from "./RegulatoryActivities.vue";
import ReusedFiles from "./ReusedFiles.vue";
import SequenceDetails from "./SequenceDetails.vue";
import { Detail } from "../../models/adaptedDetailComponent";
import GlobalHelper from "../../helpers/globalHelper";
import ErrorHelper from "../../helpers/errorHelper";
import UserRightsComponent from "../../models/userRightsComponent";
import {mapGetters} from "vuex";

export default Vue.extend({
    components: {
        DetailItem,
        LifeCycleDetailItem,
        RegulatoryActivities,
        ReusedFiles,
        SequenceDetails
    },
    data(): {
        active: number | null;
        details: any | null;
        sourceDetails: any | null;
        reuses: any | null;
        relationships: any[] | null;
        isFile: boolean;
        isDeletePlaceholder: boolean;
        selectedFuhId: number | null;
        tabs: any[];
        treeComponent: TreeComponent;
        isEditMode: boolean;
        forceDisableSaveButton: boolean;
        titleError: string | null;
    } {
        return {
            active: null,
            details: null,
            sourceDetails: null,
            reuses: null,
            isFile: false,
            isDeletePlaceholder: false,
            relationships: null,
            selectedFuhId: null,
            tabs: [
                {
                    isLc: false,
                    isRegAct: false,
                    isReused: false,
                    value: "details"
                },
                {
                    isLc: true,
                    isRegAct: false,
                    isReused: false,
                    value: "lifecycle"
                }
            ],
            treeComponent: new TreeComponent(),
            isEditMode: false,
            forceDisableSaveButton: false,
            titleError: null,
        };
    },
    methods: {
        isDisabled(detail: any): boolean {
          return this.isEditMode && detail.Header != 'Title';
        },
        updateDetails(): void {
            const url = `./Details/GetDetails?companyId=${this.companyId}&`;
            new ComponentService().getDetails(
                this.updateDetailsSuccess,
                this.treeComponent,
                url
            );
        },
        copyDetails(){
          this.sourceDetails = JSON.parse(JSON.stringify(this.details));
        },
        copySourceDetails(){
          this.details = JSON.parse(JSON.stringify(this.sourceDetails));
        },
        updateDetailsSuccess(result: any): void {
            const details = this.formatDetails(this.treeComponent, result, false);
            this.details = details;
            this.copyDetails();
            this.active = null;
            this.$nextTick(() => {
                this.active = 0;
            })
            this.$emit("detailsLoaded");
        },
        formatDetails(treeComponent: TreeComponent, details: any, isRelationships: boolean): any {
            switch (treeComponent.NodeTypeId) {
                case NodeType.Sub:
                    return details;
                case NodeType.Unit:
                    return this.orderDetailsByHeaders(details, ["Title", "Regulatory Activity Number", "Regulatory Activity Type", "State"]);
                case NodeType.Section:
                case NodeType.RepeatSection:
                case NodeType.SectionExtension:
                    return this.orderDetailsByHeaders(details, ["Title", "Section Number", "Section Path", "Number of Links", "Number of Bookmarks"]);
                case NodeType.FileUseHistory:
                    if (details.ActionTypeId && details.InfoItems)
                        details.InfoItems.push({Header: "Operation", Content: ActionTypes[details.ActionTypeId]});
                    if (treeComponent.ActionType == ActionTypes.Delete)
                        this.isDeletePlaceholder = true;
                    if (isRelationships)
                        return this.orderDetailsByHeaders(details, ["Title", "Filename", "File Path", "Checksum", "Actual Checksum", "Lifecycle Status", "State", "Number of Links", "Number of Bookmarks", "Submitted In"]);
                    if (treeComponent.FileType != FileTypes.PDF)
                        return this.orderDetailsByHeaders(details, ["Title", "Filename", "Operation", "File Path", "File Size (KB)", "Checksum", "Actual Checksum", "Lifecycle Status", "State", "Submitted In"]);
                    return this.orderDetailsByHeaders(details, ["Title", "Filename", "Operation", "File Path", "Page Count", "File Size (KB)", "Checksum", "Actual Checksum", "Lifecycle Status", "State", "Number of Links", "Number of Bookmarks", "Submitted In"]);
            }
        },
        orderDetailsByHeaders(details: any, headers: string[]): any {
            return DetailsHelper.sortDetails(details.InfoItems, headers, (detailOfInterest: Detail) =>
                headers.includes(detailOfInterest.Header)
            );
        },
        updateRelationships(): void {
            const treeComponent: TreeComponent = TreeHelper.makeTreeComponent(this.treeNode);
            const url = `./Relationships/GetRelationships?companyId=${this.companyId}`;
            new ComponentService().getDetails(
                this.updateRelationshipsSuccess,
                treeComponent,
                url
            );
        },
        updateRelationshipsSuccess(result: any): void {
            for (let j = 0; j < result.length; j += 1) {
                result[j].InfoItems = this.formatDetails(this.treeComponent, result[j], true);
            }
            this.relationships = result;
            if (this.relationships !== null) {
                const length = this.relationships.length;
                for (let i = 0; i < length; i += 1) {
                    const item = this.relationships[i];
                    item.Icon = this.getIconForActionType(item.ActionTypeId);
                }
            }
        },
        updateReusedFiles(fileUseHistoryId: number): void{
            this.tabs.push({
                isLc: false,
                isRegAct: false,
                isReused: true,
                value: "other file uses"
            });
            new ComponentService().getReusedFiles(
                this.updateReusedFilesSuccess,
                fileUseHistoryId,
                this.companyId
            );
        },
        updateReusedFilesSuccess(result: any): void{
            this.reuses = result;
        },
        getIconForActionType(actionType: number): string {
            switch (actionType) {
                case ActionTypes.Replace:
                    return this.icoReplacingFileUrl;
                case ActionTypes.Delete:
                    return this.icoDeletingFileUrl;
                case ActionTypes.Append:
                    return this.icoAppendingFileUrl;
                default:
                    return this.icoCurrentFileUrl;
            }
        },
        setTabsForType(): void {
            if (this.treeNode.FileUseHistoryId) {
                this.tabs = [
                    {
                        isLc: false,
                        isRegAct: false,
                        isReused: false,
                        value: "Details"
                    },
                    {
                        isLc: true,
                        isRegAct: false,
                        isReused: false,
                        value: "Lifecycle"
                    }
                ];
            } else if (this.treeNode.SpecId === 2) /* us3 */ {
                this.tabs = [
                    {
                        isLc: false,
                        isRegAct: false,
                        isReused: false,
                        value: "Details"
                    },
                    {
                        isLc: false,
                        isRegAct: true,
                        isReused: false,
                        value: "Regulatory Activities"
                    }
                ];
            } else {
                this.tabs = [
                    {
                        isLc: false,
                        isRegAct: false,
                        isReused: false,
                        value: "Details"
                    }
                ];
            }
        },
        resetDetails(): void {
            this.details = null;
            this.relationships = null;
            this.selectedFuhId = null;
            this.active = 0;
            this.reuses = null;
        },
        saveClick(): void {
          this.titleError = null;
          if(this.treeComponent.NodeTypeId == NodeType.SectionExtension && this.title != this.sourceTitle) {
            this.renameSectionExtension();
          }
          else {
            this.isEditMode = !this.isEditMode;
            this.copyDetails();
          }
        },
        renameSectionExtension(): void {
          let $this = this;
          let node = this.treeNode;
          if(this.treeNode) {
            $this.forceDisableSaveButton = true;
            $.ajax({
              method: "POST",
              cache: false,
              url: "./SectionExtension/Title",
              data: {
                SectionExtensionId: node.SectionExtensionId,
                Title: ($this.title || '').trim(),
                SubId: node.SubId,
              },
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              success: function (result) {
                ErrorHelper.addSnackbarMessage('The Node Extension was updated successfully.', 'success');
                let treeView = $this.treeObj();
                let selectedDataItem = treeView.dataItem($this.selectedNode);
                selectedDataItem.set("Title", $this.title);
                $this.isEditMode = !$this.isEditMode;
                $this.copyDetails();
                GlobalHelper.enqueueUpdateApplication();
              },
              error: function (result, errorText, errorDescription) {
                if((result.responseText || '').includes('Use a different title')) {
                  $this.titleError = result.responseText;
                }
                else {
                  ErrorHelper.addSnackbarMessage(`Updating the Node Extension failed. ${result.responseText || errorDescription || ''}`, 'error');
                }
              },
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              complete: function (result) {
                $this.forceDisableSaveButton = false;
              }
            });
          }
        },
    },
    computed: {
      ...mapGetters(["companyId"]),
      icoCurrentFileUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/treeicons/ico-current-file.svg"));
      },
      icoDeletingFileUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/treeicons/ico-deleting-file.svg"));
      },
      icoReplacingFileUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/treeicons/ico-replacing-file.svg"));
      },
      icoAppendingFileUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/treeicons/ico-appending-file.svg"));
      },
      iconEditUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/icon-edit.svg"));
      },
      iconXUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/icon-x-invert.svg"));
      },
      iconSaveUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/save.svg"));
      },
      iconSaveGrayUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/save_gray.svg"));
      },
      iconRelationshipUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/icon-relationship.svg"));
      },
      showIcon(): boolean {
        return this.isWebPublish && this.treeComponent &&
            (this.treeComponent.NodeTypeId == NodeType.FileUseHistory || this.treeComponent.NodeTypeId == NodeType.SectionExtension);
      },
      showEditIcon(): boolean {
        return this.showIcon && this.hasPublish && this.active == 0;
      },
      showRelationshipIcon(): boolean {
        // temporary disabled
        // this.showIcon && this.active == 1;
        return false;
      },
      titleDetail(): any {
        let details = (this.details?.InfoItems || this.details || []).filter((x: any) => x.Header == 'Title');
        return details.length ? details[0] : {};
      },
      title(): string {
        return this.titleDetail.Content;
      },
      sourceTitle(): string {
        let details = (this.sourceDetails || []).filter((x: any) => x.Header == 'Title');
        return details.length ? details[0].Content : null;
      },
      disableSave(): boolean {
        return this.forceDisableSaveButton || !(this.title || '').trim() || !!this.titleError;
      },
      selectedNode(): any {
        return this.treeNode && $(`[data-uid="${(this.treeNode as any).uid}"]`);
      },
      hasPublish(): Boolean {
        return this.isWebPublish && this.userRights.HasPublishRights;
      },
    },
    props: {
        treeNode: {
            type: Object
        },
        showDetails: {
            type: Boolean
        },
        isWebPublish: Boolean,
        treeObj: {
          type: Function
        },
        userRights: {
          type: Object as () => UserRightsComponent,
          default: new UserRightsComponent()
        },
    },
    watch: {
        treeNode(): void {
            if (this.treeNode) {
                this.treeComponent = TreeHelper.makeTreeComponent(this.treeNode);
                this.resetDetails();
                this.setTabsForType();
                this.$emit('changeShowDetails');
                this.updateDetails();
                this.selectedFuhId = (this.treeNode as TreeComponent).FileUseHistoryId;
                if (this.selectedFuhId) {
                    this.isFile = true;
                    this.updateRelationships();
                    if (this.treeComponent.IsReused){
                        this.updateReusedFiles(this.selectedFuhId);
                    }
                } else {
                    this.isFile = false;
                }
            }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        showDetails(newVal: boolean, oldVal: boolean): void {
            this.isDeletePlaceholder = false;
            if(!newVal) {
              this.isEditMode = false;
            }
            this.$emit("changeOpenMenuCount", this.showDetails)
        },
        title(newVal: string, oldVal: string): void {
          if(newVal != oldVal) {
            this.titleError = null;
          }
        }
    }
});
