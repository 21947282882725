import { Panels } from '../helpers/Enums';

declare let previewPane: any;

export default class PdfViewerGlobals {
  public static goBack(): boolean {
    return previewPane.goBack();
  }

  public static goForward(): boolean {
    return previewPane.goForward();
  }

  public static recordHistoryAtCurrentPosition(): void {
    previewPane.recordHistoryInSameDocument();
  }

  public static prevPageNavigate(): void {
    previewPane.gotoPreviousPage();
  }

  public static nextPageNavigate(): void {
    previewPane.gotoNextPage();
  }

  public static goToPdfLink(gotoR: any): void {
    previewPane.goToPdfLink(gotoR);
  }

  public static async recordHistoryInSameDocument(nonAppActiveView: number): Promise<any> {
    if (nonAppActiveView === Panels.Viewer) {
      await previewPane.recordHistoryInSameDocument();
    }
  }
}
