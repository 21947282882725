
import Vue from "vue";
import { TreeType } from "../../helpers/Enums";
import { EventBus } from "../../helpers/EventBus";
import { TreeHelper } from "../../helpers/treeHelper";
import GlobalHelper from "../../helpers/globalHelper";
import TreeLegend from "../popups/TreeLegend.vue"
import UserRightsComponent from "../../models/userRightsComponent";
import {RootMutations} from "@/store";
import {ModalWindow} from "@/enums";

export default Vue.extend({
    components: {
      TreeLegend
    },
    data(): {
        activeTreeName: string;
        disabled: boolean;
        nonSearchTree: string;
        searchTerm: string;
        showSearchBar: boolean;
        trees: string[];
        more: boolean;
        makePdf: boolean;
        treeLegend: boolean;
        treeLegendInline: boolean;
    } {
        return {
            activeTreeName: "Origin",
            disabled: false,
            nonSearchTree: "",
            searchTerm: "",
            showSearchBar: false,
            // get rid of this being a manual string?
            trees: ["Lifecycle", "Current", "Activities", "Sequence", "Origin"],
            more: false,
            makePdf: false,
            treeLegend: false,
            treeLegendInline: false,
        };
    },
    computed: {
      visibilityUrl(): string {
        // @ts-ignore
        return this.showHide ?
            GlobalHelper.getPath(require("../../Content/images/visibility_on.svg")) :
            GlobalHelper.getPath(require("../../Content/images/visibility_off.svg"));
      },
      treeUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/tree.svg"));
      },
      iconArrowUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/icon-arrow.svg"));
      },
      makePdfUrl(): string {
        return this.makePdf ?
            GlobalHelper.getPath(require("../../Content/images/icon-on-adding1.svg")) :
            GlobalHelper.getPath(require("../../Content/images/icon-on-adding2.svg"));
      }
    },
    methods: {
      changeActiveTreeName(tree: string): void {
          if (tree !== "Results") {
              this.nonSearchTree = tree;
          }

          // match the enum value
          if (tree === "Results") {
              tree = "Search";
          }

          const treeType = TreeHelper.getTreeTypeId(tree) as TreeType;
          this.$emit("changeActiveTree", treeType);
      },
      closeSearchResults(): void {
          this.activeTreeName = this.activeTreeName !== "Results" ? this.activeTreeName : this.nonSearchTree;
          this.changeActiveTreeName(this.activeTreeName);
          this.trees = this.trees.filter((tree: string) => tree !== "Results");
          this.searchTerm = "";
          this.disabled = false;
          if (this.showDetails) {
              this.$emit("changeShowDetails");
          }
      },
      searchAllResults(retry: boolean = false): void {
          if (this.searchTerm.trim().length === 0) {
              return;
          }

          this.trees.push("Results");
          this.activeTreeName = "Results";
          this.$emit("changeActiveTree", TreeType.Search);

          if (this.searchText === this.searchTerm && !retry) {
              this.disabled = false;
              return;
          }

          this.$emit("changeSearchText", this.searchTerm);
          this.disabled = true;
      },
      showSearchInput(): void {
          if (this.showDetails){
              this.$emit("changeShowDetails");
          }
      },
      createSubmission(): void {
        this.$store.commit(RootMutations.openModalWindow, ModalWindow.createSubmission);
      }
    },
    mounted(): void {
        this.nonSearchTree = this.activeTreeName;
        EventBus.$on("trees_retry", () => {
            this.searchAllResults(true);
        });

        EventBus.$on("treeToolbar_resetSearch", () => {
            this.closeSearchResults();
            this.showSearchBar = false;
        });

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        EventBus.$on("baseTree_request_expandPath", (treeId: TreeType, nodeId: string) => {
            let tree = TreeType[treeId];
            this.activeTreeName = tree;
            this.changeActiveTreeName(this.activeTreeName);
        })
    },
    props: {
        searchDisabled: {
            type: Boolean
        },
        appTitle: {
            type: String
        },
        searchText: {
            type: String
        },
        showDetails: {
            type: Boolean
        },
        showHide: {//EmptySectionPopup
            type: Boolean
        },
        isWebPublish: Boolean,
        userRights: {
          type: Object as () => UserRightsComponent,
          default: new UserRightsComponent()
        },
    },
    watch: {
        appTitle(): void {
            const isLinkToShare = new URL(window.location.href).searchParams.get("id");
            if (!isLinkToShare) {
                this.activeTreeName = "Origin";
                this.changeActiveTreeName("Origin");
            }
        },
        searchDisabled(): void {
            this.disabled = this.searchDisabled;
        },
        searchTerm(): void {
            if (this.searchTerm !== null && this.searchTerm.length === 0) {
                this.closeSearchResults();
            }
        },
        showSearchBar(): void{
            const element = document.documentElement;
            if(this.showSearchBar){
                if(!!element)
                    element.style.setProperty("--treeOffset","162");
            }
            else{
                if(!!element)
                    element.style.setProperty("--treeOffset","92");
            }
        }
    },
});
