
import Vue from "vue";
import { mapGetters } from "vuex";
import { LinkToShareHelper } from '@/helpers/linkToShareHelper';
import { DownloadHelper } from '@/helpers/downloadHelper';
import { NodeType } from "@/helpers/Enums"
import { ContextMenuItem } from "@/models";
import UserRightsComponent from "../../models/userRightsComponent";
import { ContextMenuItemKey, ModalWindow } from "@/enums";
import { treeContextMenuBuilder } from "@/builders";
import { RootMutations } from "@/store";
import AddFilesDialog from "./AddFilesDialog.vue";
import EditFileNameDialog from "./EditFileNameDialog.vue";
import EditFileTitleDialog from "./EditFileTitleDialog.vue";
import DeleteFileDialog from "./DeleteFileDialog.vue";
import PublishSubmissionUnitDialog from "@/components/details/PublishSubmissionUnitDialog.vue";
import DeleteSubmissionUnitDialog from "@/components/details/DeleteSubmissionUnitDialog.vue";
import TreeComponent from "@/models/treeComponent";

require("../../Content/Common/Common.css")

export default Vue.extend({
  components: {
    AddFilesDialog,
    EditFileNameDialog,
    EditFileTitleDialog,
    DeleteFileDialog,
    PublishSubmissionUnitDialog,
    DeleteSubmissionUnitDialog,
  },
  props: {
    treeNode: {
      type: Object
    },
    selectedNode: {
      type: Object as () => TreeComponent, default: new TreeComponent()
    },
    showContextMenu: {
      type: Boolean
    },
    tree: {
      type: String
    },
    treeObj: {
      type: Function
    },
    isWebPublish: {
      type: Boolean
    },
    userRights: {
      type: Object as () => UserRightsComponent,
      default: new UserRightsComponent()
    },
    showHide: Boolean,
    getSectionsUrl: String,
    treeIntId: Number,
  },
  data(): {
    nodeTitle: string | null;
    nodeHasChildren: boolean;
    fileExists: boolean;
    addFilesDialogOpen: boolean;
    editFileNameDialogOpen: boolean;
    editFileTitleDialogOpen: boolean;
    deleteFileDialogOpen: boolean;
    publishSubmissionUnitDialogOpen: boolean;
    deleteSubmissionUnitDialogOpen: boolean;
    isSectionExtension: boolean;
    isRepeatSection: boolean;
    treeNodeFuhId: number | null;
    lastTreeNode: any | null;
    actionTreeTypes: string[];
    contextMenuItems: ContextMenuItem[];
  } {
    return {
      nodeTitle: "",
      nodeHasChildren: false,
      fileExists: false,
      addFilesDialogOpen: false,
      editFileNameDialogOpen: false,
      editFileTitleDialogOpen: false,
      deleteFileDialogOpen: false,
      publishSubmissionUnitDialogOpen: false,
      deleteSubmissionUnitDialogOpen: false,
      isSectionExtension: false,
      isRepeatSection: false,
      treeNodeFuhId: null,
      lastTreeNode: null,
      actionTreeTypes: ['Current', 'Lifecycle'],
      contextMenuItems: [],
    }
  },
  computed: {
    ...mapGetters(["companyId", "loaded"]),
  },
  watch: {
    treeNode(): void {
      if (!this.treeNode) {
        return;
      }

      this.fileExists = this.treeNode.FileUseHistoryId ? this.treeNode.DoesExist : false;
      this.treeNodeFuhId = this.treeNode.FileUseHistoryId;
      this.isSectionExtension = !!this.treeNode.SectionExtensionId && !this.treeNodeFuhId;
      this.isRepeatSection = !!this.treeNode.RepeatableSectionId && this.treeNode.NodeType == NodeType.RepeatSection && !this.treeNode.StartRepeatGroup;
      this.lastTreeNode = this.treeNode;
      this.nodeTitle = this.treeNode.Title || "";
      this.nodeHasChildren = this.treeNode.HasChildren;

      this.$nextTick(() => {
        this.$emit("addClickListener", this.fileExists);
      });

      this.contextMenuItems = treeContextMenuBuilder.build(this.isWebPublish, this.treeNode);
    }
  },
  methods: {
    reloadParentItem() {
      const treeView = this.treeObj();
      const parentTreeItem = treeView.findByUid(this.lastTreeNode.parentNode().uid);
      const parentTreeNode = treeView.dataItem(parentTreeItem);
      
      treeView.collapse(parentTreeItem);
      
      parentTreeNode.loaded(false);
      parentTreeNode.load();
      
      treeView.one("dataBound", () => {
        treeView.expand(parentTreeItem);
      });
    },
    onContextMenuItemClick(menuItemKey: ContextMenuItemKey) {
      switch (menuItemKey) {
        case ContextMenuItemKey.copyLink:
          LinkToShareHelper.copyLink(this.treeNode, this.companyId);
          break;
        case ContextMenuItemKey.editSubmission:
          this.$store.commit(RootMutations.setSelectedSub, this.treeNode.SubId);
          this.$store.commit(RootMutations.openModalWindow, ModalWindow.editSubmission);
          break;
        case ContextMenuItemKey.publishSubmission:
          this.publishSubmissionUnitDialogOpen = true;
          break;
        case ContextMenuItemKey.deleteSubmission:
          this.deleteSubmissionUnitDialogOpen = true;
          break;
        case ContextMenuItemKey.addFiles:
          this.addFilesDialogOpen = true;
          break;
        case ContextMenuItemKey.downloadFile:
          DownloadHelper.downloadFile(this.companyId, this.treeNode.FileUseHistoryId, null);
          break;
        case ContextMenuItemKey.editFileName:
          this.editFileNameDialogOpen = true;
          break;
        case ContextMenuItemKey.editFileTitle:
          this.editFileTitleDialogOpen = true;
          break;
        case ContextMenuItemKey.deleteFile:
          this.deleteFileDialogOpen = true;
          break;
        default:
          console.log(`Menu item ${ContextMenuItemKey[menuItemKey]} clicked.`);
          break;
      }
    },
    onAddFilesDialogClose(uploaded: boolean) {
      this.addFilesDialogOpen = false;

      if (!uploaded) {
        return;
      }

      const treeView = this.treeObj();
      const treeItem = treeView.findByUid(this.lastTreeNode.uid);
      const treeNode = treeView.dataItem(treeItem);
      
      let iterateTreeNode = treeNode;
      do {
        iterateTreeNode.set("HasFiles", true);
        iterateTreeNode = iterateTreeNode.parentNode();
      } while(iterateTreeNode)

      treeNode.hasChildren = true;
      treeView.collapse(treeItem);

      treeNode.loaded(false);
      treeNode.load();
      
      this.$emit("refreshInventory");

      treeView.one("dataBound", () => {
        treeView.expand(treeItem);
      });
    },
    onEditFileNameDialogClose(renamed: boolean) {
      this.editFileNameDialogOpen = false;
      
      if (!renamed) {
        return;
      }
      
      this.reloadParentItem();
      
      this.$emit("refreshInventory");
      this.$emit("changeSelectedNode", new TreeComponent());
    },
    onEditFileTitleDialogClose(renamed: boolean) {
      this.editFileTitleDialogOpen = false;
      
      if (!renamed) {
        return;
      }
      
      this.reloadParentItem();
      this.$emit("refreshInventory");
    },
    onDeleteFileDialogClose(deleted: boolean) {
      this.deleteFileDialogOpen = false;
      
      if (!deleted) {
        return;
      }
     
      const treeView = this.treeObj();
      const treeItem = treeView.findByUid(this.lastTreeNode.uid);
      const treeNode = treeView.dataItem(treeItem);
      const parentTreeNode = treeNode.parentNode();
      
      if (treeNode.FileUseHistoryId === this.selectedNode.FileUseHistoryId) {
        this.$emit("changeSelectedNode", new TreeComponent());
      }
      
      treeView.remove(treeItem);
      
      let iterateTreeNode = parentTreeNode;
      do {
        iterateTreeNode.set("HasFiles", iterateTreeNode.children.view().some((x: any) => x.HasFiles || x.FileType !== null));
        iterateTreeNode = iterateTreeNode.parentNode();
      } while(iterateTreeNode)
      
      this.$emit("refreshInventory");
   }
  }
});
