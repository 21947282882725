
import Vue from "vue";
import { TreeType } from "../../helpers/Enums";
import { EventBus } from "../../helpers/EventBus";
import BaseTree from "./BaseTree.vue";
import SearchContainer from "./SearchContainer.vue";
import TreeToolbar from "./TreeToolbar.vue";
import UserRightsComponent from "../../models/userRightsComponent";
import TreeComponent from "@/models/treeComponent";
require("../../Content/Trees/Trees.css")

export default Vue.extend({
    components: {
        BaseTree,
        SearchContainer,
        TreeToolbar
    },
    data(): {
        activities: boolean;
        current: boolean;
        lifecycle: boolean;
        origin: boolean;
        searchDisabled: boolean;
        searchVisible: boolean;
        sequence: boolean;
        resetDontHide: boolean;
    } {
        return {
            activities: false,
            current: false,
            lifecycle: false,
            origin: true,
            searchDisabled: false,
            searchVisible: false,
            sequence: false,
            resetDontHide: false,
        };
    },
    methods: {
        removeDisable(): void {
            this.searchDisabled = true; // toggling so the watcher triggers
            this.$nextTick(() => {
                this.searchDisabled = false;
            })
        },
        setValuesFalse(): void {
            this.activities = false;
            this.current = false;
            this.lifecycle = false;
            this.origin = false;
            this.sequence = false;
            this.searchVisible = false;
        },
        showTree(tree: TreeType): void {
            this.setValuesFalse();
            if (tree === null) {
                return;
            }

            switch (tree) {
                case TreeType.Lifecycle:
                    this.lifecycle = true;
                    break;
                case TreeType.Current:
                    this.current = true;
                    break;
                case TreeType.Activities:
                    this.activities = true;
                    break;
                case TreeType.Sequence:
                    this.sequence = true;
                    break;
                case TreeType.Origin:
                    this.origin = true;
                    break;
                case TreeType.Search:
                    this.searchVisible = true;
                    break;
            }
        }
    },
    mounted() {
        EventBus.$on("navLink_treeSelect", (tree: TreeType) => {
            this.setValuesFalse();
            this.showTree(tree);
        });

        document.getElementById("lifecycle-tree-wrapper")!.onscroll = function(e: Event): void{
            const element = document.documentElement;
            if(!!element)
                element.style.setProperty("--lifecycleOrnamentOffset",(e.currentTarget as HTMLElement).scrollLeft.toString());
        };
        document.getElementById("current-tree-wrapper")!.onscroll = function(e: Event): void{
            const element = document.documentElement;
            if(!!element)
                element.style.setProperty("--currentOrnamentOffset",(e.currentTarget as HTMLElement).scrollLeft.toString());
        };
        document.getElementById("regulatoryactivity-tree-wrapper")!.onscroll = function(e: Event): void{
            const element = document.documentElement;
            if(!!element)
                element.style.setProperty("--regActOrnamentOffset",(e.currentTarget as HTMLElement).scrollLeft.toString());
        };
        document.getElementById("sequence-tree-wrapper")!.onscroll = function(e: Event): void{
            const element = document.documentElement;
            if(!!element)
                element.style.setProperty("--sequenceOrnamentOffset",(e.currentTarget as HTMLElement).scrollLeft.toString());
        };
        document.getElementById("origin-tree-wrapper")!.onscroll = function(e: Event): void{
            const element = document.documentElement;
            if(!!element)
                element.style.setProperty("--originOrnamentOffset",(e.currentTarget as HTMLElement).scrollLeft.toString());
        };
        document.getElementById("searchTreeWrapper")!.onscroll = function(e: Event): void{
            const element = document.documentElement;
            if(!!element)
                element.style.setProperty("--searchOrnamentOffset",(e.currentTarget as HTMLElement).scrollLeft.toString());
        };
    },
    props: {
        bind: {
            type: Boolean
        },
        activeTree: {
            type: Number
        },
        selectedNode:{
          type: Object as () => TreeComponent, default: new TreeComponent()
        },
        showHide: {
          type: Boolean
        },
        showDetails: {
          type: Boolean
        },
        showContextMenu: {
          type: Boolean
        },
        treeSearchResults: {
          type: Array
        },
        searchText: {
          type: String
        },
        appTitle: {
          type: String
        },
        isWebPublish: Boolean,
        userRights: {
          type: Object as () => UserRightsComponent,
          default: new UserRightsComponent()
        },
    },
    watch: {
        activeTree(): void {
            this.showTree(this.activeTree);
        }
    }
});
