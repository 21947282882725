
import Vue from "vue";
import CopyButton from './CopyButton.vue';

export default Vue.extend({
    components: {
      CopyButton
    },
    props: {
      detail: Object,
      isEditMode: Boolean,
      error: String,
    },
});
