
import Vue from "vue";
import { TreeType } from '../../helpers/Enums';

export default Vue.extend({
    data(): {
        tree: any;
    } {
        return {
            tree: TreeType.Origin,
        }
    },
    methods: {
        expandNode(): void {
            const data = {
                fileUseHistoryId: this.selectedFuhId,
                TreeType: this.tree
            };
            this.$emit('changeInventoryNodeToOpen', data)
        }
    },
    props: {
        treeNode: {
            type: Object
        },
        showMenu: {
            type: Boolean
        },
        selectedFuhId: {
            type: Number
        },
        activeTree: {
          type: Number
        },
        isWebPublish: Boolean
    },
    watch: {
        activeTree(): void {
            this.tree =  this.activeTree;
        }
    }
});
