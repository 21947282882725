
import Vue from "vue";
import ClipboardHelper from '../../helpers/clipboardHelper';
import CopyButton from './CopyButton.vue';

export default Vue.extend({
    components: {
      CopyButton
    },
    props: {
        detail: Object
    },
    methods: {
        copyDetail(detail: string) {
          ClipboardHelper.copyDetail(detail);
        }
    }
});
