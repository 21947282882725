
import { Grid, GridInstaller } from "@progress/kendo-grid-vue-wrapper";
import Vue from "vue";
import TreeComponent from "../../models/treeComponent";
import InventoryComponent from "../../models/inventoryComponent";
import DetailsComponent from "../../models/detailsComponent";
import { TreeType, ActionTypes } from "../../helpers/Enums";
import $ from 'jquery';
import ServiceComponent from '../../services/componentService';
import InventoryContextMenu from '../popups/InventoryContextMenu.vue';
import GlobalHelper from "../../helpers/globalHelper";
import {mapGetters} from "vuex";
require("../../Content/Common/Common.css")

// required for kendo
Vue.use(GridInstaller);
export default Vue.extend({
    components: {
        InventoryContextMenu
    },
    data(): {
        total: number;
        emptySearch: boolean;
        loaded: boolean;
        showContextMenu: boolean;
        service: ServiceComponent;
        contextElement: string;
        dataSource: any;
        menuXOffset: number;
        menuYOffset: number;
        selectedFuh: number | null;
    } {
        return {
            total: 0,
            emptySearch: false,
            loaded: false,
            showContextMenu: false,
            service: new ServiceComponent(),
            contextElement: '',
            dataSource: null,
            menuYOffset: 0,
            menuXOffset: 0,
            selectedFuh: null
        };
    },
    methods: {
          getActionTemplate(data: DetailsComponent): string | null {
              if(!data.ActionTypeId) {
                  return "";
              }
              return ActionTypes[data.ActionTypeId];
          },
          getTitleTemplate(data: DetailsComponent): string {
              (window as any).openMenu = this.openMenu;
              return `<div class="inventory-column-title">
                          <span class="k-command-cell" role="gridcell"><a onclick="window.openMenu()" role="button" class="k-button-icontext k-grid-contextButton"><span class="k-icon k-i-more-vertical"></span></a></span>
                          <img src="${this.getInventoryTitleImg(data.ActionTypeId, data.DoesExist, data.IsPlaceholder)}" />
                          <span>${data.Title}</span>
                      </div>`;
          },
          getInventoryTitleImg(actionType: number, doesExist: boolean, isPlaceholder: boolean): string | null {
              if(isPlaceholder) {
                return this.icoPlaceholderUrl;
              }

              switch(actionType) {
                  case 0: case 1: case 5: case 6:
                      return doesExist ? this.icoCurrentFileUrl : this.newMissingUrl;
                  case 2:
                      return this.icoDeletingFileUrl;
                  case 3:
                      return doesExist ? this.icoReplacingFileUrl : this.replaceMissingUrl;
                  case 4:
                      return doesExist ? this.icoAppendingFileUrl : this.appendMissingUrl;
              }
              return null;
          },
          getNoRecordsTemplate(): {
              template: string;
          } {
              return {
                  template: `<div id="inventoryEmptySectionMessage">
                                  <img src='${this.emptyEnventoryUrl}' />
                                  <span>No files in this section</span>
                              </div>`
              };
          },
          encodeData(data: any): any {
              for (let key in data) {
                  if(typeof(data[key]) === "string") {
                      data[key] = encodeURI(data[key])
                  }
              }
              return data;
          },
          openMenu(): void {
              const e = (window.event as any);
              e.stopPropagation();
              const selectedRow = $(e.currentTarget).closest('tr');
              const item: any = this.thisGrid().dataItem(selectedRow);
              this.menuXOffset = e.pageX;
              this.menuYOffset = e.pageY + 10;

              this.selectedFuh = item.FileUseHistoryId;
              this.showContextMenu = true;

              $(document).click(() => {
                  this.showContextMenu = false;
                  $(document).off('click');
              })

          },
          connectEvents(): void {
              var gridRows = $("#inventoryGrid .k-grid-content tr")
              this.showContextButton(gridRows, false)
              gridRows
                  .mouseenter((e) => {
                      if(this.activeTree != TreeType.Search)
                          this.showContextButton(e.currentTarget, true)
                  })
                  .mouseleave((e) => this.showContextButton(e.currentTarget, false))
          },
          showContextButton(el: any, show: boolean): void {
              $(el)
                  .closest("tr")
                  .addClass(show ? "gridRowMenu" : "gridRowMenuHidden")
                  .removeClass(show ? "gridRowMenuHidden" : "gridRowMenu");
          },
          getDataSourceObject(): kendo.data.DataSource {
              let self: any = this;
              if(this.dataSource != null)
                  return this.dataSource;
              this.dataSource = new kendo.data.DataSource({
                  schema: {
                      data: "Data",
                      total: "Total"
                  },
                  pageSize: 25,
                  serverPaging: true,
                  serverSorting: true,
                  transport: {
                      read: {
                          cache: false,
                          dataType: "json",
                          url: `./Inventory/GetInventoryPaging?companyId=${self.companyId}`,
                          data(): InventoryComponent {
                              const model: InventoryComponent = self.treeNode as InventoryComponent;
                              self.total = model.Total;
                              if(model.TreeTypeId == null) {
                                  model.TreeTypeId = TreeType.Origin;
                              }
                              return model;
                          }
                      },
                      parameterMap(data: any): any {
                          data = self.encodeData(data);
                          if(!data.sort || data.sort.length === 0) {
                              return {...data, ...{sortColumn: "SectionNumber", sortDirection: 0}};
                          }
                          data.sortColumn = data.sort[0].field;
                          data.sortDirection = data.sort[0].dir === "asc" ? 0 : 1;
                          delete data.sort;
                          return data;
                      }
                  },
              });
              return this.dataSource;
          },
          thisGrid(): kendo.ui.Grid {
              return (this.$refs.inventoryGrid as Grid).kendoWidget() as kendo.ui.Grid;
          }
      },
    computed: {
      ...mapGetters(["companyId"]),
      emptyEnventoryUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/empty_inventory.svg"));
      },
      icoCurrentFileUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/treeicons/ico-current-file.svg"));
      },
      newMissingUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/treeicons/new-missing.svg"));
      },
      icoDeletingFileUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/treeicons/ico-deleting-file.svg"));
      },
      icoReplacingFileUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/treeicons/ico-replacing-file.svg"));
      },
      replaceMissingUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/treeicons/replace-missing.svg"));
      },
      icoAppendingFileUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/treeicons/ico-appending-file.svg"));
      },
      appendMissingUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/treeicons/append-missing.svg"));
      },
      icoPlaceholderUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/treeicons/ico-current-placeholder.svg"));
      },
    },
    watch: {
        treeNode(): void {
            if(this.treeNode) {
                this.thisGrid().dataSource.page(1);
            } else {
                this.thisGrid().dataSource.data().empty();
            }
        }
    },
    props: {
        treeNode: {
          type: Object as () => TreeComponent
        },
        activeTree: Number,
        isWebPublish: Boolean
    }
});
