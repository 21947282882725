
import Vue from "vue"
import CopyButton from './CopyButton.vue';
import { ContactType } from "@/enums";

export default Vue.extend({
    components: {
      CopyButton
    },
    data() {
      return {
        sortedItems: []
      };
    },
    props: {
        header: {
            type: String
        },
        items: {
            type: Array
        },
    },
    mounted() {
      this.sortedItems = this.getSortedDetails(this.items);
    },
    methods: {
      getName(item: any) {
        let name = '';
        const details = item.ContactDetails as any;
        if(details?.length > 0) {
          const givenName = details.find((x: any) => x.ContactTypeId === ContactType.GivenName)?.ContactName;
          const familyName = details.find((x: any) => x.ContactTypeId === ContactType.FamilyName)?.ContactName;

          if(givenName) name += givenName + ' ';
          if(familyName) name += familyName + ' ';

          name = name.trim();
        }

        if(!name) name = item.Name;

        return name;
      },
      getSortedDetails(items: any) {
        for(let item of items) {
          const ordered = {
            [ContactType.BusinessTelephoneNumber]: 1,
            [ContactType.FaxNumber]: 2,
            [ContactType.MobileNumber]: 3,
            [ContactType.TelephoneNumber]: 4,
            [ContactType.Email4]: 5,
            [ContactType.Email7]: 6,
            [ContactType.GivenName]: 7,
            [ContactType.FamilyName]: 8,
            [ContactType.MiddleInitial]: 9,
            [ContactType.MiddleName]: 10,
            [ContactType.Organization]: 11,
            [ContactType.Unspecified5]: 12,
            [ContactType.Unspecified0]: 13,
          } as any;

          const max = 1000;
          item.Name = this.getName(item);
          item.ContactDetails = (item.ContactDetails || []).sort((item1: any, item2: any) => {
            const a = ordered[item1.ContactTypeId] || max;
            const b = ordered[item2.ContactTypeId] || max;

            if (a > b) return 1;
            else if (a == b) return 0;
            else return -1;
          });

          for(let contact of item.ContactDetails) {
            contact.ContactName = this.correctName(contact.ContactName);
          }
        }

        return items;
      },
      correctName(name: string) {
        return (name || '').replace(/tel\:\+|mailto\:/g,'');
      }
    },
})
