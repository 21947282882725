
import Vue from "vue";
export default Vue.extend({
    props: {
        id: {
            type: String
        },
        message: {
            type: String
        },
        showLoader: {
            type: Boolean
        },
        width: {
            type: String
        }
    }
});
