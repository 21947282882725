
import $ from "jquery";
import Vue from "vue";
import { EventBus } from "../../helpers/EventBus";
import ComponentService from "../../services/componentService";
import ErrorHelper from "../../helpers/errorHelper";
import BaseFullscreenLoader from "./BaseFullscreenLoader.vue";
import {mapGetters} from "vuex";

export default Vue.extend({
    components: {
        BaseFullscreenLoader
    },
    data(): {
        message: string;
        locationData: any;
        nodeToSelect: string;
        service: ComponentService;
        showLoader: boolean;
    } {
        return {
            message: "Opening your shared link",
            locationData: null,
            nodeToSelect: "",
            service: new ComponentService(),
            showLoader: false
        };
    },
    computed: {
       ...mapGetters(["companyId"])
    },
    mounted() {
        EventBus.$on("baseTree_autoExpanded", this.onExpandFinished);
        EventBus.$on("baseTree_refreshed", this.expandPath);
        EventBus.$on("baseTree_scrollFinished", this.onScrollFinished);
        EventBus.$on("appSelector_clearSelectedNode", this.clearLocationData);
    },
    methods: {
        clearLocationData(): void{
            this.locationData = null;
        },
        // TODO: vue-ify this.
        hightlightNode(node: any): void {
            const el: any = $(node);
            el.before("<div/>");
            el.prev()
                .width(el.width())
                .height(el.height())
                .css({
                    "background-color": "#ffff99",
                    opacity: ".5",
                    position: "absolute"
                })
                .fadeOut(1000);
        },
        expandPath(treeIntId: number): void {
            if (
                this.locationData == null ||
                treeIntId !== this.locationData.TreeType) {
                return;
            }

            EventBus.$emit("navLink_treeSelect", treeIntId);
            this.$nextTick(() =>
                EventBus.$emit(
                    "baseTree_request_expandPath",
                    parseInt(this.locationData.TreeType, 10),
                    this.locationData.TreePath
                )
            );
        },
        onScrollFinished(node: any): void {
            this.hightlightNode(node);
            this.clearLocationData();
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onExpandFinished(treeId: number): void {
            this.showLoader = false;
            EventBus.$emit(
                "baseTree_request_select",
                this.locationData.TreeType,
                this.nodeToSelect
            );
        },
        onTreePathData(refreshInfoAndTrees: boolean = true, result: any): void {
            if (result.ErrorMessage) {
                ErrorHelper.addSnackbarMessage(result.ErrorMessage, "error");
                this.showLoader = false;
                this.$emit("linkToShareFail");
            } else {
                if(refreshInfoAndTrees)
                    this.$emit("changeAppTitle", `${result.AppType} ${result.AppNumber}`);
                result.TreePath = result.TreePath.split(",");
                this.nodeToSelect = result.TreePath.splice(-1, 1)[0];
                this.locationData = result;
                if(refreshInfoAndTrees)
                    this.$nextTick(() => EventBus.$emit("mainComponent_refreshTree"));
                else
                    this.expandPath(result.TreeType);
            }
        }
    },
    props: {
        linkShareToOpen: {
            type: String
        },
        appTitle: {
          type: String
        },
        inventoryNodeToOpen: {
          type: Object
        }
    },
    watch: {
        linkShareToOpen(guid: string) {
            this.showLoader = true;
            this.service.getTreePathFromGuid(this.companyId, guid, this.onTreePathData.bind(null, true));
        },
        inventoryNodeToOpen(nodeInfo: any) {
            this.showLoader = true;
            this.message = "Locating file"
            this.service.getTreePathByFileId(this.companyId, nodeInfo, this.onTreePathData.bind(null, false));
        }
    }
});
