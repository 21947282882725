
import Vue from "vue";
import { Contact, ContactType, ContactDetailType, ContactDetail } from "@/models/WebPublish";
import { applicationsService } from "@/services";

interface ComponentState {
  id: number;
  givenName: string;
  middleName: string;
  familyName: string;
  middleInitial: string;
  contactTypeId: number | null;
  organization: string;
  details: ContactDetail[];
  searchOrganization: string,
  organizations: string[],
}

export default Vue.extend({
  async beforeMount() {
    await this.updateForm(this.currentContact);
  },
  data(): ComponentState {
    return {
      id: 0,
      givenName: "",
      middleName: "",
      familyName: "",
      middleInitial: "",
      contactTypeId: null,
      organization: "",
      details: [{ Id: 0, ContactDetailTypeId: null, Value: "" }],
      searchOrganization: "",
      organizations: [],
    }
  },
  props: {
    contactTypes: { type: Array as () => Array<ContactType>, default: () => [] },
    contactDetailTypes: { type: Array as () => Array<ContactDetailType>, default: () => [] },
    currentContact: { type: Object as () => Contact },
    companyId: { type: Number },
  },
  computed: {
    saveDisabled(): boolean {
      return !(this.contactTypeId && this.givenName && this.familyName && this.organization &&
        this.details.some((x: ContactDetail) => x.ContactDetailTypeId && x.Value));
    },
    showDeleteDetailButton(): boolean {
      return this.details.length > 1;
    }
  },
  methods: {
    onOrganizationTyping(event: KeyboardEvent) {
      const target = event.target as HTMLInputElement;
      this.onOrganizationChange(target.value);
    },
    onOrganizationChange(organization: string) {
      this.organization = organization;
    },
    async loadOrganizations(startWith: string) {
      if (startWith === null) return;
      this.organizations = await applicationsService.getContactOrganizations(this.companyId, startWith ?? "");
    },
    async updateForm(contact: Contact) {
      this.id = contact.Id;
      this.givenName = contact.GivenName;
      this.familyName = contact.FamilyName;
      this.middleName = contact.MiddleName;
      this.middleInitial = contact.MiddleName;
      this.contactTypeId = contact.ContactTypeId;
      this.organization = contact.Organization;
      this.details = contact.Details.map(x => { return { ...x }});
      this.searchOrganization = contact.Organization;
      this.organizations = [];
      await this.loadOrganizations(contact.Organization ?? "");
    },
    addContactDetail() {
      this.details.push({ Id: 0, ContactDetailTypeId: null, Value: "" });
    },
    deleteContactDetail(contactDetail: ContactDetail) {
      this.details = this.details.filter((x: ContactDetail) => x != contactDetail);
    },
    saveContact() {
      this.$emit("save", {
        Id: this.id,
        GivenName: this.givenName,
        FamilyName: this.familyName,
        MiddleName: this.middleName,
        MiddleInitial: this.middleInitial,
        ContactTypeId: this.contactTypeId,
        Organization: this.organization,
        Details: [...this.details.filter((x: ContactDetail ) => x.ContactDetailTypeId && x.Value)],
        SubId: this.currentContact.SubId
      } as Contact);
    },
    closeWindow() {
      this.$emit("close");
    },
  },
  watch: {
    currentContact(contact: Contact) {
      if (contact) {
        this.updateForm(contact);
      }
    },
    async searchOrganization(startWith: string) {
      this.loadOrganizations(startWith);
    }
  },
});
