
import Vue from "vue";
import Info from "./dropdowns/Info.vue";
import { mapMutations, mapState } from "vuex";

export default Vue.extend({
  components: {
    Info
  },
  computed: {
    ...mapState(["userGuideUrl", "showAppSelector",])
  },
  methods: {
    ...mapMutations(["changeContactDialogState", "changeInfoDialogState", "changeUserGuideUrl", "changeAppSelector"]),
    signOut(): void {
      this.$auth.signOut();
    },
    home(): void {
      if (!this.showAppSelector) {
        this.changeAppSelector();
      }
    },
  }
});
