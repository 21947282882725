import $ from 'jquery';
import ComponentService from '../services/componentService';
import ErrorHelper from './errorHelper';
import { EventBus } from './EventBus';

export class DownloadHelper {
  public static async downloadFile(companyId: number, loadedDocFuhId: number | null, loadedPdfPath: any): Promise<void> {
    const fuhId: number | null = loadedDocFuhId;
    const fuhPath: any = loadedPdfPath;
    const time: number = Date.now();
    try {
      if (fuhPath) {
        await new ComponentService().doesFileExistByPath(companyId, fuhPath.source, fuhPath.target);
        // catch in MainComponent.
        EventBus.$emit('changeFileToDownload', `./Viewer/GetFileForDownloadByPath?companyId=${companyId}&${$.param(fuhPath)}#${time}`);
      } else if (fuhId) {
        await new ComponentService().doesFileExistById(companyId, fuhId);
        // catch in MainComponent.
        EventBus.$emit('changeFileToDownload', `./Viewer/GetFileForDownloadById?companyId=${companyId}&fuhId=${fuhId}#${time}`);
      }
    } catch (ex) {
      ErrorHelper.addSnackbarMessage('File did not download.', 'error');
    }
  }
}
