
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import NavBar from "./navs/NavBar.vue";
import NavDrawer from "./navs/NavDrawer.vue";

export default Vue.extend({
  components: {
    NavBar,
    NavDrawer,
  },
  computed: {
    ...mapState([
      "showAppSelector",
      "showTrees",
      "appTitle",
      "nonAppActiveView",
      "userGuideUrl",
      "drawerState",
      "isPublishMode",
      "webApplicationName"
    ])
  },
  methods: {
    ...mapMutations([
      "changeDrawerState",
      "changeAppSelector",
      "changeShowTrees",
      "changeInfoDialogState",
      "changeContactDialogState",
      "changeUserGuideUrl",
      "changeOpenMenuCount",
    ])
  },
  props: {
    accessDenied: Boolean
  }
});
