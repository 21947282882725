import LinkedNode from './linked-node';

// a doubly linked list that holds a limited number of nodes
export default class LmtdDblLinkedList<T> {
  public constructor(public limit: number) {}

  private current: LinkedNode<T> | null = null;

  private count = 0;

  private forwardCount = 0;

  private head: LinkedNode<T> | null = null;

  public clear(): void {
    this.current = null;
    this.count = 0;
    this.head = null;
  }

  public add(obj: T, isPlaceholder = false): void {
    const node = new LinkedNode<T>(obj, isPlaceholder);
    if (this.current && this.current.isPlaceholder) {
      this.replaceCurrent(node);
    } else {
      this.addNewNode(node);
    }
  }

  private replaceCurrent(node: LinkedNode<T>): void {
    const prev = this.current ? this.current.prev : null;
    if (prev) {
      prev.next = node;
      node.prev = prev;
    }
    this.current = node;

    if (this.head && this.count === 1) {
      this.head = node;
    }
  }

  private addNewNode(node: LinkedNode<T>): void {
    if (this.current === null) {
      this.current = node;
      this.head = node;
    } else {
      this.recountIfNecessary();
      this.current.next = node;
      node.prev = this.current;
      this.current = node;
    }
    this.incrementCount();
  }

  public goForward(): T | null {
    let next = null;
    if (this.current) {
      next = this.current.next;
    }
    if (next != null) {
      this.current = next;
    }
    if (this.forwardCount > 0) this.forwardCount--;
    return next && !next.isPlaceholder ? next.data : null;
  }

  public goBack(): T | null {
    let prev = null;
    if (this.current) {
      prev = this.current.prev;
    }
    if (prev != null) {
      this.incrementForwardCount(this.current);
      this.current = prev;
    }
    if (prev && prev.isPlaceholder) return this.goBack();

    return prev ? prev.data : null;
  }

  public currentData(): T | null {
    return this.current ? this.current.data : null;
  }

  public canGoBack(): boolean {
    return this.current ? this.current.prev != null && this.count > 1 : false;
  }

  public canGoForward(): boolean {
    return this.current ? this.current.next != null && this.forwardCount > 0 : false;
  }

  private incrementCount(): void {
    this.count++;
    this.forwardCount = 0;
    if (this.count > this.limit) {
      this.trimHead();
    }
  }

  private incrementForwardCount(nextNode: LinkedNode<T> | null): void {
    if (nextNode && !nextNode.isPlaceholder) {
      this.forwardCount++;
    }
  }

  private trimHead(): void {
    if (this.head && this.head.next) {
      const oldHead = this.head;
      const newHead = this.head.next;
      oldHead.next = null;
      newHead.prev = null;
      this.head = newHead;
      this.count--;
    }
  }

  private recountIfNecessary(): void {
    if (this.current && this.current.next != null) {
      let node: LinkedNode<T> | null = this.current;
      let newCount = 0;
      while (node != null) {
        newCount++;
        node = node.prev;
      }
      this.count = newCount;
    }
  }
}
