
import Vue from "vue";
import { EventBus } from "../../helpers/EventBus";
import { Panels } from "../../helpers/Enums";
import { ViewerToolbarHelper } from "../../helpers/ViewerToolbarHelper";
import { DownloadHelper } from '../../helpers/downloadHelper';
import { FileComponent } from "../../models/fileComponent";
import {mapGetters} from "vuex";

export default Vue.extend({
    data(): {
        pdfViewer: any;
        viewer: any;
        drawer: boolean;
        showPdfViewer: boolean;
        enableToolbarButtons: boolean;
        moreMenu: boolean;
        loadedFile: FileComponent;
        loadedFilePath: any;
    } {
        return {
            pdfViewer: null,
            viewer: null,
            drawer: false,
            showPdfViewer: true,
            enableToolbarButtons: false,
            moreMenu: false,
            loadedFile: new FileComponent,
            loadedFilePath: null
        };
    },
    computed: {
      ...mapGetters(["companyId"]),
    },
    methods: {
        downloadFile(): void {
            DownloadHelper.downloadFile(this.companyId, this.loadedFile.FuhId, this.loadedFilePath);
        },
        zoomIn() {
            ViewerToolbarHelper.zoomIn();
        },
        zoomOut() {
            ViewerToolbarHelper.zoomOut();
        },
        fitWidth() {
            ViewerToolbarHelper.fitWidth();
        },
        fitPage() {
            ViewerToolbarHelper.fitPage();
        },
        search() {
            ViewerToolbarHelper.search();
        },
        fullscreen() {
            ViewerToolbarHelper.fullscreen();
        }
    },
    mounted(): void {
        EventBus.$on("changeNonAppActiveView", (viewer: number) => {
            this.$emit("changeNonAppActiveView", viewer);
        });

        EventBus.$on("changeLoadedDoc", (file: FileComponent) => {
            this.$emit("changeLoadedDoc", file);
        });
    },
    props: {
        toolbarTooWide: Boolean,
        nonAppActiveView: Number,
        isDocumentLoaded: Boolean,
        loadedDoc: Object,
        loadedPdfDoc: Object
    },
    watch: {
        nonAppActiveView() {
            this.showPdfViewer = this.nonAppActiveView === (Panels.Viewer as number);
        },
        isDocumentLoaded(){
            this.enableToolbarButtons = this.isDocumentLoaded;
        },
        moreMenu(){
            this.$emit("changeOpenMenuCount", this.moreMenu);
        },
        loadedDoc(file: FileComponent): void {
            this.loadedFile = file;
        },
        loadedPdfDoc(file: any): void {
            this.loadedFilePath = file;
        }
    }
});
