
import Vue from "vue";
import BaseFullscreenLoader from "./BaseFullscreenLoader.vue";

export default Vue.extend({
    components: {
        BaseFullscreenLoader
    },
    data(): {
        message: string;
    } {
        return {
            message: "Opening application"
        };
    },
    props: {
        showLoader: {
            type: Boolean
        }
    }
});
