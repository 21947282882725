
import Vue from "vue";
import Dialog from "../popups/Dialog.vue";
import { mapState } from "vuex";
import { applicationsService } from "@/services";
import ErrorHelper from "@/helpers/errorHelper";
import { BadRequestError } from "@/models/Errors";

export default Vue.extend({
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Dialog
  },
  computed: {
    ...mapState(["activeCompany", "selectedApplicationId"])
  },
  props: {
   value: {
     type: Boolean
   },
   treeNode: {
     type: Object
   },
  },
  data(): {
    publishing: boolean;
    publishingDelay: number;
  } {
    return {
      publishing: false,
      publishingDelay: 1000,
    };
  },
  methods: {
    async publishSub(): Promise<void> {
      let $this = this;
      $this.publishing = true;
      try {
        await applicationsService.publishSubmissionUnit(
          this.activeCompany.Id,
          this.selectedApplicationId,
          this.treeNode.SubId
        );
        await new Promise(resolve => setTimeout(resolve, this.publishingDelay));
        ErrorHelper.addSnackbarMessage('Publish has been successfully queued.', 'success');
      }
      catch (e) {
        if (e instanceof BadRequestError) {
          ErrorHelper.addSnackbarMessage(e.message, 'error', '');
        } else {
          ErrorHelper.addSnackbarMessage('Publish was unsuccessful. Please try again or contact your System Administrator if the issue persists.', 'error', (e as Error).message, true);
        }
      }
      finally {
        this.$emit('input', false);
        this.publishing = false;
      }
    }
  }
});
