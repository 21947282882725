
import Vue from 'vue'

import { DownloadHelper } from "../../helpers/downloadHelper"
import { FileComponent } from '../../models/fileComponent';
import GlobalHelper from "../../helpers/globalHelper";
import {mapGetters} from "vuex";

export default Vue.extend({
    data(): {
        messageType: string;
        loadFile: FileComponent | null;
    } {
        return {
            messageType: '',
            loadFile: null
        };
    },
    methods: {
        downloadFile() {
            DownloadHelper.downloadFile(this.companyId, (this.loadFile as FileComponent).FuhId, null);
        }
    },
    computed: {
        ...mapGetters(["companyId"]),
        missingFileUrl(): string {
            // @ts-ignore
            return GlobalHelper.getPath(require("../../Content/images/missing_file.svg"));
        },
        unViewableUrl(): string {
            // @ts-ignore
            return GlobalHelper.getPath(require("../../Content/images/un_viewable.svg"));
        },
        placeholderUrl(): string {
            // @ts-ignore
            return GlobalHelper.getPath(require("../../Content/images/placeholder.svg"));
        },
    },
    watch: {
        showOverlayMessage(type: string): void {
            this.messageType = type;
        },
        loadedDoc(loadFile: FileComponent): void {
            this.loadFile = loadFile;
        }
    },
    props: {
      showOverlayMessage: {
        type: String
      },
      loadedDoc: {
        type: Object as () => FileComponent
      }
    }
});
