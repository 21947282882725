
import Vue from "vue";
import IdleDialog from 'common-vue-components/components/Dialogs/IdleDialog.vue';
import PageHeader from "@/components/PageHeader.vue"
import PageFooter from "@/components/PageFooter.vue"
import { mapMutations, mapState, mapActions } from "vuex";
import router from "./router";
import AppSelectorLoader from "@/components/appselector/AppSelectorLoader.vue";
import { AlertMessage } from "@/models/alertMessage";
import { EventBus } from "@/helpers/EventBus";
import GlobalHelper from "@/helpers/globalHelper";
import Snackbar from "common-vue-components/components/Messaging/Snackbar.vue";
import { Company } from "common-vue-components/models/company";
import ContactDialog from "@/components/popups/ContactDialog.vue";
import InformationDialog from "@/components/popups/InformationDialog.vue";
// Views
import MainView from "@/components/MainView.vue"
import ModalWindowManager from "@/components/ModalWindowManager.vue";
import { ModalWindow } from "@/enums";
import { RootMutations } from "./store";

export default Vue.extend({
  components: {
    AppSelectorLoader,
    PageHeader,
    MainView,
    PageFooter,
    ContactDialog,
    InformationDialog,
    ModalWindowManager,
    IdleDialog
  },
  data() {
    return {
      gridLoaded: false,
      totalSnackHeights: 0,
      snackbarIndex: 0,
      linkShareToOpen: null,
      accessDenied: false,
      initialized: false
    }
  },
  computed: {
    ...mapState([
      "activeCompany",
      "companies",
      "areCompaniesLoading",
      "activeTree",
      "appTitle",
      "canGoBack",
      "canGoForward",
      "contactDialogState",
      "drawerState",
      "failedLinkToShare",
      "fileToDownload",
      "infoDialogState",
      "inventoryNodeToOpen",
      "isDocumentLoaded",
      "isFullScreen",
      "isSearchOpen",
      "isUnsupportedFile",
      "loadedDoc",
      "loadedPdfPath",
      "loadingInformation",
      "nonAppActiveView",
      "openMenuCount",
      "recentsLoaded",
      "searchText",
      "selectedNode",
      "showAppSelector",
      "showHide",
      "showContextMenu",
      "showDetails",
      "showOverlayMessage",
      "showTrees",
      "sessionExpired",
      "treeLegendState",
      "treeSearchResults",
      "userGuideUrl",
      "userSettings",
      "userRights",
      "loadedPdfDoc",
      "isPublishMode",
      "modalWindow",
      "webApplicationName",
    ])
  },
  methods: {
    ...mapMutations([
      "changeRecentsLoaded",
      "changeActiveTree",
      "changeAppSelector",
      "changeAppTitle",
      "changeContactDialogState",
      "changeDrawerState",
      "changeFailedLinkToShare",
      "changeInfoDialogState",
      "changeInventoryNodeToOpen",
      "changeIsFullScreen",
      "changeIsDocumentLoaded",
      "changeIsSearchOpen",
      "changeIsUnsupportedFile",
      "changeLoadedDoc",
      "changeLoadingInformation",
      "changeNonAppActiveView",
      "changeOpenMenuCount",
      "changeSearchText",
      "changeSelectedNode",
      "changeShowContextMenu",
      "changeShowDetails",
      "changeShowHide",
      "changeSessionExpired",
      "changeShowNonPdfViewer",
      "changeShowOverlayMessage",
      "changeShowPreviewPane",
      "changeShowTrees",
      "changeTreeLegendState",
      "changeTreeSearchResults",
      "changeUserGuideUrl",
      "resetLoadingInformation",
      "changeFileToDownload",
      "changeCanGoBack",
      "changeCanGoForward",
      "setHideAppSelector",
      "changeAppState"
    ]),
    ...mapActions([
      "getUserSettings",
      "getUserRights",
      "updateBookmarkPanelOpenSetting",
      "getCompaniesForUser",
      "changeActiveCompany"
    ]),
    isLoggedIn(): boolean {
      const isLoggedIn = !!this.authState && !!this.authState.isAuthenticated && !this.$router.currentRoute.path.includes("/login");
      const currentSessionPath = window.sessionUrl + "Main";
      const isRedirected = window.location.pathname.startsWith(currentSessionPath);

      if (isLoggedIn && !isRedirected) {
        router.push(currentSessionPath, () => { });
      }

      return isLoggedIn && isRedirected;
    },
    addSnackbarError(alertMessage: AlertMessage, showContactSupport: boolean): void {
      const $this = this;
      $this.snackbarIndex += 1;
      // eslint-disable-next-line prefer-const
      let component = Vue.extend(Snackbar);
      // eslint-disable-next-line new-cap
      const instance = new component({
        propsData: {
          text: alertMessage.Message,
          index: $this.snackbarIndex,
          timeout: alertMessage.DismissTime,
          topValue: $this.totalSnackHeights,
          colorKey: alertMessage.Color,
          showContactSupport: showContactSupport,
          errorMessage: alertMessage.ErrorMessage,
          logId: alertMessage.LogId,
          eventBus: EventBus
        }
      });

      instance.$mount();
      const container = (this.$refs.container as any)?.$el;
      container && container.prepend(instance.$el);
    },
    addSnackbarHeight(height: number): void {
      this.totalSnackHeights += height;
    },
    subtractSnackbarHeight(height: number): void {
      this.totalSnackHeights -= height;
    },
    getUrlParam(name: string): any {
      return new URL(window.location.href).searchParams.get(name);
    },
    isCreatingApp(): boolean {
      return this.modalWindow === ModalWindow.applicationWizard;
    },
    createNewApp() {
      this.$store.commit(RootMutations.openModalWindow, ModalWindow.applicationWizard);
    }
  },
  async mounted(): Promise<void> {
    if (GlobalHelper.IsIe()) {
      const error =
        new AlertMessage(
          "Internet Explorer is no longer a supported browser, please use Firefox, Chrome, Edge, or Safari.",
          "error",
          0
        );
      this.addSnackbarError(error, false);
    }

    EventBus.$on("addSnackbar", (alertMessage: AlertMessage, showContactSupport: boolean): void => {
      this.addSnackbarError(alertMessage, showContactSupport);
    });

    EventBus.$on("SnackCreated", (height: number) => {
      this.addSnackbarHeight(height);
    });

    EventBus.$on("SnackDestroyed", (index: number, height: number) => {
      this.subtractSnackbarHeight(height);
    });
  },
  watch: {
    authState: {
      async handler() {
        if (!this.initialized && this.authState?.isAuthenticated) {
          this.initialized = true;

          await this.getCompaniesForUser();
          const id = this.getUrlParam("id");

          if (id) {
            const company = this.companies.find((x: Company) => x.Id == this.getUrlParam("companyId") && !x.isExpired);
            if(company) {
              await this.changeActiveCompany(company, false);
              this.$nextTick(() => {
                this.linkShareToOpen = id;
                this.setHideAppSelector();
              });
            }
            else {
              this.accessDenied = true;
            }
          }
          else {
            this.accessDenied = !this.companies.length || this.companies.every((x: Company) => x.isExpired);
          }
        }
      },
      deep: true
    }
  }
});
