
import Vue from "vue";
import GlobalHelper from "../../helpers/globalHelper";
export default Vue.extend({
    data(): {
        contactDialog: boolean;
        contactInfos: any[];
    } {
        return {
            contactDialog: false,
            contactInfos: [
                {
                    href: "mailto:support@certara.com",
                    icon: "mdi-email-outline",
                    title: "Email Support: support@certara.com"
                },
                {
                    href: "tel:+1-919-852-4620",
                    icon: "mdi-phone-outline",
                    title: "Call Support: +1-919-852-4620"
                }
            ]
        };
    },
    watch: {
        contactDialogState(): void {
            this.contactDialog = this.contactDialogState;
        },
        contactDialog(): void {
            if (this.contactDialog === false) {
                this.$emit("changeContactDialogState");
            }
        }
    },
    computed: {
      iconXUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/icon-x.svg"));
      },
    },
    props: {
      contactDialogState: {
        type: Boolean
      }
    }
});
