
import Vue from "vue";
import { mapState } from "vuex";

import Dialog from "./Dialog.vue";
import ErrorHelper from "@/helpers/errorHelper";

import UploadDialog, { UploadDialogRef } from 'common-vue-components/components/Dialogs/Upload/UploadDialog.vue';
import { UploadEventArgs } from "common-vue-components/components/Dialogs/Upload/types/uploadEventArgs";
import { CompleteFilesUploadRequest, StartFilesUploadRequest } from "@/models/WebPublish/Requests";
import { StartFileUploadResponse } from "@/models/WebPublish/Responses";
import { filesService } from "@/services";
import { UploadFileEventArgs } from "common-vue-components/components/Dialogs/Upload/types/uploadFileEventArgs";
import { UploadFileStatus } from "common-vue-components/components/Dialogs/Upload/types/uploadFileStatus";
import { UploadStatus } from "common-vue-components/components/Dialogs/Upload/types/uploadStatus";

export default Vue.extend({
    components: {
      // eslint-disable-next-line vue/no-reserved-component-names
      Dialog,
      UploadDialog
    },
    props: {
      open: { type: Boolean },
      treeNode: { type: Object, default: null },
    },
    computed: {
      ...mapState(["activeCompany", "selectedApplicationId"]),
      uploadDialog(): UploadDialogRef {
        return this.$refs.uploadDialog as any as UploadDialogRef;
      }
    },
    data(): {
      showWarning: boolean;
      showUploadDialog: boolean;
      uploadingFiles: StartFileUploadResponse[];
      uploadedFiles: UploadFileEventArgs[],
      uploaded: boolean
      } {
      return {
        showWarning: false,
        showUploadDialog: false,
        uploadingFiles: [],
        uploadedFiles: [],
        uploaded: false
      };
    },
    watch: {
      open: function() {
        this.uploaded = false;
        if (this.open) {
          if (this.treeNode.WarnAddFiles) {
            this.showWarning = true;
          } else {
            this.showUploadDialog = true;
          }
        } else {
          this.uploadingFiles = [];
          this.uploadedFiles = [];
          this.showWarning = false;
          this.showUploadDialog = false;
        }
      },
      showWarning: function () {
        if (!this.showWarning && !this.showUploadDialog) {
          this.$emit('close');
        }
      }
    },
    methods: {
      uploadFiles(): void {
        this.showUploadDialog = true;
        this.showWarning = false;
      },

      async handleUpload(e: UploadEventArgs): Promise<void> {
        const startUploadRequest: StartFilesUploadRequest = {
          AppId: this.selectedApplicationId,
          SubId: this.treeNode.SubId,
          Files: e.files.map(uploadFile => ({
            FileName: uploadFile.path,
            Size: uploadFile.size
          }))
        }
        
        try {
          this.uploadingFiles = await filesService.startFilesUpload(
            this.activeCompany.Id,
            startUploadRequest
          );
        } catch(error) {
          this.uploadDialog.setUploadStatus(UploadStatus.Error);
          ErrorHelper.addSnackbarMessage("The file(s) start upload failed", 'error', error.message, true);
          return;
        }
        
        try {
          await this.uploadDialog.upload({
            files: this.uploadingFiles.map(uploadingFile => ({
              status: UploadFileStatus.Success,
              uploadId: uploadingFile.UploadId,
              fileName: uploadingFile.NewFileName,
              filePath: uploadingFile.OriginalFileName,
              partSize: uploadingFile.PartSize,
              partUrls: uploadingFile.PartUrls
            }))
          });
        } catch {
          ErrorHelper.addSnackbarMessage("The file(s) upload failed", "error", "", true);
          return;
        }
        
        this.uploadDialog.setUploadStatus(UploadStatus.Uploading);
                
        const completeUploadRequest: CompleteFilesUploadRequest = {
          AppId: this.selectedApplicationId,
          SubId: this.treeNode.SubId,
          ReviewSectionId: this.treeNode.ReviewSectionId,
          Files: this.uploadingFiles.map(uploadingFile => ({
            UploadId: uploadingFile.UploadId,
            OriginalFileName: uploadingFile.OriginalFileName,
            FilePath: uploadingFile.FilePath,
            PartETags: this.uploadedFiles.find(f => f.uploadId === uploadingFile.UploadId)!.eTags
          }))
        }
        
        try {
          await filesService.completeFilesUpload(
            this.activeCompany.Id,
            completeUploadRequest
          );
        } catch {
          this.uploadedFiles = [];
          this.uploadDialog.setUploadStatus(UploadStatus.Error);
          ErrorHelper.addSnackbarMessage("The file(s) complete upload failed", "error", "", true);
          return;
        }
        
        this.uploaded = true;
        
        this.uploadedFiles.forEach(uploadedFile => {
          this.uploadDialog.setFileUploadSuccess(uploadedFile.path);
        })

        this.uploadedFiles = [];
        this.uploadDialog.setUploadStatus(UploadStatus.Success);
        
        ErrorHelper.addSnackbarMessage("The file(s) added successfully", "success");
      },
      handleUploadFile(e: UploadFileEventArgs): void {
        this.uploadedFiles.push(e);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      handleUploadDialogClose(): void {
        this.showUploadDialog = false;
        this.showWarning = false;
        this.$emit('close', this.uploaded);
      }
    }
  });
