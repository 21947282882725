
import Vue from "vue";
import GlobalHelper from "../../helpers/globalHelper";
export default Vue.extend({
    data(): {
        currentYear: number;
        imageUrl: string;
        infoDialog: boolean;
        version: string;
    } {
        return {
            currentYear: new Date().getFullYear(),
            imageUrl: "",
            infoDialog: false,
            version: "",
        };
    },
    mounted(): void {
        this.version = window.version;
        this.imageUrl = this.synchrologoUrl;
    },
    watch: {
        infoDialogState(): void {
            this.infoDialog = this.infoDialogState;
        },
        infoDialog(): void {
            if (this.infoDialog === false) {
                this.$emit("changeInfoDialogState");
            }
        }
    },
    props: {
      infoDialogState: {
        type: Boolean,
      },
      appName: {
        type: String,
      }
    },
    computed: {
      synchrologoUrl(): string {
        // @ts-ignore
        return GlobalHelper.getPath(require("../../Content/images/certaralogo.svg"));
      },
    }
});
