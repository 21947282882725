
import Vue from "vue";
import GlobalHelper from "../../helpers/globalHelper";
export default Vue.extend({
  data(): {
    dialogModel: boolean;
    maxWidth: number;
  } {
    return {
      dialogModel: false,
      maxWidth: 585,
    };
  },
  watch: {
    value(): void {
      this.dialogModel = this.value;
    },
    dialogModel(newVal: boolean, oldVal: boolean): void {
      if(newVal != oldVal) {
        this.$emit("input", newVal);
      }
    }
  },
  computed: {
    iconXUrl(): string {
      // @ts-ignore
      return GlobalHelper.getPath(require("../../Content/images/icon-x.svg"));
    },
  },
  props: {
    value: {
      type: Boolean
    },
    title: {
      type: String
    },
    showButtons: {
      type: Boolean
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    okText: {
      type: String,
      default: 'OK'
    },
    okEnabled: {
      type: Boolean,
      default: true
    },
    okLoading: {
      type: Boolean,
      default: false
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    cancelLoading: {
      type: Boolean,
      default: false
    },
    width: {
      type: [String, Number],
      default: null
    },
    persistent: {
      type: Boolean,
      default: false
    }
  }
});
